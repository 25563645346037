<script lang="ts" setup>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { DashboardType } from '../../model/enum';

const { t } = useI18n();

const props = defineProps<{ selected: DashboardType }>();
const emit = defineEmits<{ (e: 'change-default', v: DashboardType): void }>();

const visible = defineModel<boolean>('show');

const localSelected = ref<DashboardType>();
const categories = ref([
	{ name: t('revenue'), key: DashboardType.revenue },
	{ name: t('technicians'), key: DashboardType.tech }
]);

const save = () => {
	if (localSelected.value) emit('change-default', localSelected.value);
	visible.value = false;
};

watch(
	() => visible.value,
	() => {
		if (visible.value) {
			localSelected.value = props.selected;
		}
	}
);
</script>

<template>
	<Dialog
		v-model:visible="visible"
		:header="$t('selectDefault')"
		modal
		style="width: 18rem"
	>
		<div
			v-for="category in categories"
			:key="category.key"
			class="tw3-flex tw3-items-center tw3-gap-2 tw3-mb-4"
		>
			<RadioButton
				v-model="localSelected"
				:inputId="category.key"
				name="dynamic"
				:value="category.key"
			/>
			<label class="!tw3-text-lg" :for="category.key">{{
				category.name
			}}</label>
		</div>
		<div class="tw3-flex tw3-justify-end tw3-gap-2">
			<Button
				:label="$t('cancel')"
				severity="secondary"
				type="button"
				@click="visible = false"
			/>
			<Button :label="$t('save')" type="button" @click="save" />
		</div>
	</Dialog>
</template>
