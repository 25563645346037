<template>
  <div id="app">
    <template v-if="$auth.isAuthenticated || $auth.skipAuth">
      <loading-screen
        v-if="(showFirstLoading && !$auth.skipAuth) || showLoading"
      />
      <div
        class="glassbiller-container"
        :class="{ 'glassbiller-fixed-layout': $route.meta.fixedLayout }"
      >
        <PageHeader
          v-if="!$auth.skipAuth && !hideSidebar"
          :title="headerTitle"
          :allowQuickBooks="true"
          :changeDetected="isChangesDetected"
          :filePickerKey="filePickerKey"
        />

        <div class="glassbiller-content-wrapper">
          <side-bar-v2 v-if="!hasTechCommissionPermission && !hideSidebar" />
          <div class="glassbiller-content">
            <div class="glassbiller-page-wrapper">
              <router-view
                v-if="userAccountIsActive"
                v-show="!showFirstLoading"
                :key="$route.fullPath"
                @changesDetected="changesDetected"
                @updateHeaderTitle="updateHeaderTitle"
                :filePickerKey="filePickerKey"
                @loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationDialog v-if="!$auth.skipAuth" />

      <shop-rates-dialog
        v-if="shopRateData"
        v-model="showShopRateDialog"
        :rate="shopRateData.rate"
        :shopRates="shopRateData.shopRates"
        :shop_id="shopRateData.shopId"
        :defaultName="shopRateData.defaultName"
        :applyRateMethod="shopRateData.applyRateMethod"
        :autoApply="true" />
      <AlertDialog />
      <UnsavedChangesDialog :url="url" v-if="!$auth.skipAuth" />
      <GlobalNotification v-if="!$auth.skipAuth" />
      <scheduler-dialog v-if="!$auth.skipAuth" />
      <DocumentDialogDynamic
        :filePickerKey="filePickerKey"
        v-if="!$auth.skipAuth"
      />
      <DocumentPopover
        @popoverShown="showDocumentPopover = false"
        :showPopover="showDocumentPopover"
        :filePickerKey="filePickerKey"
        v-if="!$auth.skipAuth"
      />

      <nielsen-moller-alert-dialog
        :visible.sync='nmAlertVisible'
        :shopId.sync='nmAlertShopId'
        :syncShopId="true"
      />

      <!-- We need this here so we can target the popover header more easily -->
      <!-- <div id="warehouseMygrantWrapper"></div>
      <div id="warehousePilkingtonWrapper"></div> -->
      <!-- <acknowledgement-alert
        :messages="componentsToShowInAcknowledgeDialog"
        :visible='showAcknowledgmentDialog' 
        :required='true' 
        @acknowledged='acknowledgeTermsAndConditions'
        button_text='Accept Policies, Terms and Conditions'
        :title="'IMPORTANT: Updated Terms of Use Agreement' + ($store.state.user.nags_price_increase_acknowledged !== 1 ? ' and Notification of price increase.' : '')" 
      /> -->
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/inc/HeaderV2/Index.vue'
import GlobalNotification from '@/components/GlobalNotification/GlobalNotification.vue'
import User from '@/scripts/objects/user'
import AlertDialog from '@/components/modals/AlertDialog.vue'
import UnsavedChangesDialog from '@/components/modals/UnsavedChangesDialog.vue'
import API from '@/services/Api'
import { UtilityMixin } from '@/scripts/helpers/utilities'
import NotificationDialog from '@/components/modals/NotificationDialog.vue'
import {
  schedulerNamespacedActions,
  schedulerNamespacedMutations
} from '@/store/modules/scheduler/scheduler.types'
import SchedulerDialog from '@/components/modals/SchedulerDialog'
import ShopRatesDialog from '@/components/JobPageV2/helpers/dialogs/ShopRatesDialog'
import DocumentDialogDynamic from '@/components/DynamicDocument/DocumentDialogDynamic.vue'
import DocumentPopover from '@/components/DynamicDocument/DocumentPopover.vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import { mapActions } from 'pinia'
import pusher from './services/PusherService'
import { call, get, sync } from 'vuex-pathify'
import { shoppingCartActions } from '@/store/modules/shopping-cart/types'
import SideBarV2 from '@/components/inc/SideBarV2/Index'
import LoadingScreen from '@/components/Helpers/LoadingScreen'
import '@material-icons/font/css/all.css'
import AcknowledgementAlert from '@/components/Helpers/AcknowledgementAlert'
import AuditLog from '@/scripts/objects/audit_log'
import {
  permissions,
  permissionCheck
} from '@/scripts/helpers/permissions.helpers'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'
import SoftwareContractGlassbiller from '@/components/alertText/SoftwareContractGlassbiller_2_.vue'
import NagsPriceIncrease from '@/components/alertText/NagsPriceIncrease.vue'
import NielsenMollerAlertDialog from '@/components/modals/NielsenMollerAlertDialog.vue'

export default {
  name: 'App',
  data() {
    return {
      headerTitle: 'GlassBiller',
      filePickerKey: '',
      isChangesDetected: false,
      url: '',
      showFirstLoading: false,
      showLoggedInComponents: false,
      showDocumentPopover: false,
      checkHashInterval: null,
      loadingTimer: null,
      SoftwareContractGlassbiller,
      NagsPriceIncrease,
      nmAlertVisible: false,
      nmAlertShopId: null,
      showShopRateDialog: false,
      shopRateData: null
    }
  },
  components: {
    PageHeader,
    AlertDialog,
    GlobalNotification,
    NotificationDialog,
    UnsavedChangesDialog,
    SchedulerDialog,
    DocumentDialogDynamic,
    DocumentPopover,
    SideBarV2,
    LoadingScreen,
    AcknowledgementAlert,
    NielsenMollerAlertDialog,
    ShopRatesDialog
  },
  async created() {
    document.title = 'GlassBiller'
    this.$root.$on('runAppSetupCommand', this.runAppSetupCommand)
    this.$root.$on('showAppPageLoading', this.showAppPageLoading)

    this.$root.$on('createSquareDeviceSubscription', this.createSquareDeviceSubscription)
    this.$root.$on('removeSquareDeviceSubscription', this.removeSquareDeviceSubscription)

    const self = this
    this.$router.beforeEach((to, from, next) => {
      self.showAppPageLoading()
      next()
    })
    this.$router.afterEach((to, from, next) => {
      self.showFirstLoading = false
    })
    const token = VueCookies.get('token')
    if (token) {
      axios.defaults.headers.common.Auth = token
    }
    window.addEventListener(
      'dragover',
      function (e) {
        e = e || event
        e.preventDefault()
      },
      false
    )
    window.addEventListener(
      'drop',
      function (e) {
        e = e || event
        e.preventDefault()
      },
      false
    )
    window.addEventListener('openVendor', this.openVendorFromVue3)
  },
  beforeDestroy: function () {
    this.$root.$off('runAppSetupCommand', this.runAppSetupCommand)
    this.$root.$off('showAppPageLoading', this.showAppPageLoading)

    this.$root.$off('createSquareDeviceSubscription', this.createSquareDeviceSubscription)
    this.$root.$off('removeSquareDeviceSubscription', this.removeSquareDeviceSubscription)
    window.removeEventListener('hydrateNags', this.hydrateNagsFromVue3)
    window.removeEventListener('openVendor', this.openVendorFromVue3)
  },
  methods: {
    ...mapActions(useVendorStore, { viewVendor: 'openView' }),
    openVendorFromVue3(event) {
      this.viewVendor(event.detail.id)
    },
    async hydrateNagsFromVue3(event) {
      this.shopRateData = {
        ...event.detail,
        applyRateMethod: (newRate) => {
            const newEvent = new CustomEvent('returnNagsToVue3', { detail: {
              isCreate: event.detail.isCreate,
              newRate
            } 
          })
          window.dispatchEvent(newEvent)
        }
      }
      await this.$nextTick(() => {
        this.showShopRateDialog = true
      })
    },
    showAppPageLoading() {
      const self = this
      clearTimeout(self.loadingTimer)
      self.showFirstLoading = true
      self.loadingTimer = setTimeout(function () {
        self.showFirstLoading = false
      }, 5000)
    },
    updateShowLoggedInComponents() {},
    runAppSetupCommand() {
      this.getConfigDetails()

      const self = this
      User.getUserAndShopsWithCookieToken(
        function (user, shops, organizations, roles, nmShopToInvite) {
          pusher.init()
          if (user.account_id === -1) {
            let hash = self.$cookies.get('hash')
            AuditLog.createV2AuditLog({
              event: 'User Logged Out Due to No Account',
              description: `${user.username} logged out`,
              event_id: 57,
              job_id: null,
              salesidejob_id: null,
              status: null,
              ref_table: 'user',
              ref_id: user.id,
              shop_id: user.shop_id,
              internal_note: `Hash: ${hash}`
            })
            self.$auth.logout({ returnTo: document.location.origin })
          } else {
            self.$store.dispatch('setUser', user)
            self.$store.dispatch(
              'setWarehouseHideAdhesiveCache',
              user.warehouse_hide_adhesive_cache
            )
            self.$store.dispatch(
              'setWarehouseGroupByCache',
              user.warehouse_group_by_cache
            )
            self.$store.dispatch(
              'setWarehouseShopIdCache',
              user.warehouse_shop_id_cache
            )
            self.$store.dispatch(
              'setWarehouseIsPartsRequired',
              user.warehouse_is_parts_required_cache
            )
            self.$store.dispatch('setRoles', roles)
            self.$store.dispatch('setShops', shops)
            self.$store.dispatch('setOrganizations', organizations)
            self.$store.commit(schedulerNamespacedMutations.setStateValue, {
              key: 'filters.shops',
              value: user.scheduler_selected_shops
            })
            self.getShoppingCartItemsCount()
            if (self.$store.state.user.has_access_to_twilio_phone_numbers) {
              try {
                self.getUnreadTextMessageThreadsCount()
                pusher.subscribeToRelatedShops()
              } catch (err) {}
            }
            self.getOpenTicketCount()

            // open nm invite dialog if applicable
            if (nmShopToInvite) {
              self.nmAlertShopId = nmShopToInvite
              self.nmAlertVisible = true
            }
          }
        },
        function () {
          self.$store.dispatch('setUser', [])
          self.$store.dispatch('setShops', [])
          self.$store.dispatch('setOrganizations', [])
          // window.location.href = '/logout' commented out for development
        }
      )
    },
    updateHeaderTitle(event) {
      this.headerTitle = event
    },
    changesDetected(event) {
      this.isChangesDetected = event
    },
    getConfigDetails() {
      const self = this
      if (!this.filePickerKey || this.filePickerKey.length === 0) {
        API({ url: '/api/config' }).then(
          (res) => {
            if (
              res &&
              res.data &&
              res.data.filePickerKey &&
              res.data.filePickerKey.length > 0
            ) {
              self.filePickerKey = res.data.filePickerKey
            } else {
              setTimeout(() => {
                self.getConfigDetails()
              }, 5000)
            }
          },
          function (error) {
            if ((error + '').indexOf('status code 401') !== -1) {
            } else {
              setTimeout(() => {
                self.getConfigDetails()
              }, 2000)
            }
          }
        )
      }
    },
    loading(isLoading) {
      this.showLoading = isLoading
    },
    getUnreadTextMessageThreadsCount: call(
      'twilio/textMessages/getUnreadThreadsCount'
    ),
    getShoppingCartItemsCount() {
      return this.$store.dispatch(shoppingCartActions.getItemsCount)
    },
    acknowledgeTermsAndConditions(val) {
      User.updateUserTermsAndConditions(val)
      this.$store.dispatch('setNagsPriceIncreaseAcknowledged', val)
    },
    getOpenTicketCount: call(
      'support/getOpenTicketCount'
    ),
    createSquareDeviceSubscription(shopId, deviceId) {
      pusher.subscribeToSquareDeviceNotifications(shopId, deviceId)
    },
    removeSquareDeviceSubscription(shopId, deviceId) {
      pusher.unsubscribeToSquareDeviceNotifications(shopId, deviceId)
    }
  },
  mounted() {
    this.showLoading = false
    this.$store.dispatch(schedulerNamespacedActions.setStateValue, {
      key: 'isOpen',
      value: false
    })
    window.addEventListener('hydrateNags', this.hydrateNagsFromVue3)
    this.capitalizeClassWatcherInit()
  },
  destroyed() {
    this.capitalizeClassWatcherDestroy()
    this.$store.dispatch('triggerGoToPage', '')
    clearInterval(this.checkHashInterval)
  },
  computed: {
    showLoading: sync('globalLoading'),
    hasTechCommissionPermission() {
      return permissionCheck(permissions.TECHCOMMISSION, this.$store.state)
    },
    userAccountIsActive: get('userAccountIsActive'),
    allowQuickBooks() {
      var allowQuickBooks = false
      var allowedShopsTemp = [597, 4, 849, 852, 853, 861, 764, 837, 267]
      try {
        const tempShops = this.$store.state.shops
        for (var i = 0; i < tempShops.length; i++) {
          if (allowedShopsTemp.includes(tempShops[i].id)) {
            allowQuickBooks = true
            i = tempShops.length
          }
        }
      } catch (err) {}
      return allowQuickBooks
    },
    pageChange() {
      return this.$store.getters.pageTrigger
    },
    documentDialogTrigger() {
      return this.$store.getters.documentConfig
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated
    },
    hideSidebar() {
      const openPaths = ['/signup', '/signature_capture', '/gb_form', '/paymentPortal']
      if (!this.$route.name) return true

      // newclient is special, we want to match on any variation, independent of casing
      if (this.$route.path.toLowerCase().indexOf('/newclient') > -1) {
        return true
      }

      for (var i = 0; i < openPaths.length; i++) {
        if (this.$route.path.indexOf(openPaths[i]) > -1) {
          return true
        }
      }
      return false
    },
    showAcknowledgmentDialog() {
     const tempArray = []
      if (this.$store?.state?.user) {
        if ((this.$store.state.user.nags_price_increase_acknowledged !== 1 || 
          this.$store.state.user.terms_and_conditions_acknowledged !== 1) &&
          this.componentsToShowInAcknowledgeDialog.length) {
          return true
        }
      }
      return false
    },
    componentsToShowInAcknowledgeDialog() {
      const tempArray = []
      if (this.$store?.state?.user && Object.keys(this.$store.state.user) && 
        this.$route.name !== 'Signature Capture' && this.$route.name !== 'Payment Portal') {
        if (this.$store.state.user.nags_price_increase_acknowledged !== 1) {
          tempArray.push(NagsPriceIncrease)
        }
        if (this.$store.state.user.terms_and_conditions_acknowledged !== 1) {
          tempArray.push(SoftwareContractGlassbiller)
        }
      }
      return tempArray
    }
  },
  watch: {
    $route(to, from) {
      this.updateShowLoggedInComponents()
    },
    pageChange: function (val) {
      if (val !== '') {
        this.url = val
        if (this.isChangesDetected) {
          this.$root.$emit('bv::show::modal', 'unsavedDialog')
        } else {
          if (this.url.startsWith('/')) {
            this.$router.push(this.url)
            if (this.url === '/jobs/new' && this.$job) {
              this.$job.resetStore()
              window.location.reload()
            }
          } else if (this.url === 'refresh') {
            this.$router.go()
          } else {
            window.location.href = this.url
          }
          this.$store.dispatch('triggerGoToPage', '')
        }
      } else {
        this.url = ''
      }
    },
    documentDialogTrigger: function (val) {
      if (val !== null) {
        if (val.config.popover) {
          this.showDocumentPopover = true
        } else {
          this.$root.$emit('bv::show::modal', 'dynamicDocumentModal')
        }
      }
    },
    isAuthenticated: {
      handler(val) {
        if (val && !this.$auth.skipAuth) {
          this.runAppSetupCommand()
        }
      },
      immediate: true
    }
  },
  beforeRouteUpdate(to, from, next) {},
  mixins: [UtilityMixin]
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
</style>
<style src='../node_modules/vue-multiselect/dist/vue-multiselect.min.css'></style>

<style scoped>
.app-loading-view {
  min-width: 100%;
}
</style>

<style lang="scss" scoped>
@import "./App.scss";
</style>
