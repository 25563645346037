<template>
  <div
    class="calendar-job-card"
    :class="{ hidden: item.hidden }"
    :style="cardStyle"
    @click="card.action"
    @dragstart="itemDrag(item)"
    @dragend="itemDrag()"
  >
    <div v-if="color" class="left-border" :style="{ background: color }"></div>
    <div>
      <div class="content">
        <div>
          {{ card.title }}
        </div>
        <div>
          <span class="extra" v-if="card.extra" :style="cardStyle">
            {{ card.extra }}
          </span>
          <v-icon :name="card.icon" class="icon" scale="0.7" :style="cardStyle" />
        </div>
      </div>
      <span v-if="showInstallLocation && card.installCity && card.installState">
        <v-icon name="map-marker-alt" scale="0.7" class="t-mb-1"/>
        {{ card.installCity }}, {{ card.installState }}
      </span>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { getCardTextColor } from '@/scripts/helpers/scheduler.helpers'
import * as R from 'ramda'
import actions from '@/store/modules/scheduler_v3/types'
import { getJobTimeStringWithMinutes } from '@/scripts/helpers/job.helpers'

const icons = {
  start: 'play',
  pickup: 'cog',
  end: 'stop',
  dropoff: 'briefcase'
}

export default {
  props: ['item', 'color'],
  computed: {
    card() {
      return {
        title: R.cond([
          [R.prop('job'), R.path(['job', 'customer_name'])],
          [R.prop('tech'), () => 'Tech Home'],
          [R.prop('shop'), R.path(['shop', 'name'])],
          [R.prop('vendor'), R.path(['vendor', 'name'])]
        ])(this.item),
        icon: icons[this.item.type],
        installCity: this.item?.job?.install_g_address?.city || null,
        installState: this.item?.job?.install_g_address?.state || null,
        extra: R.cond([
          [R.prop('job'), R.pipe(R.prop('job'), getJobTimeStringWithMinutes)]
        ])(this.item),
        action: this[this.item.type] || R.always
      }
    },
    isSolidCardView: get('sch_v3/isSolidCardView'),
    showInstallLocation: get('sch_v3/showInstallLocation'),
    textColor() {
      return getCardTextColor(this.color)
    },
    cardStyle() {
      return this.isSolidCardView
        ? { color: `${this.textColor} !important`, background: this.color }
        : {}
    }
  },
  methods: {
    itemDrag(item) {
      this.$root.$emit('dragging-item', item)
    },
    getJobDetails: call(actions.getJobDetails),
    openApptModal() {
      this.$root.$emit('open-appt-modal', this.item)
    },
    dropoff() {
      if (this.item.job?.id) this.getJobDetails(this.item.job?.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/CalendarJobCard.scss";
</style>
