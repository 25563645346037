import Api from '@/services/Api'

const getAgingReportData = async ({ shopId, groupBy, dateTo, dateFrom, type }) => {
  const { data } = await Api.get('/api/reports/aging', {
    params: { shopId, groupBy, dateFrom, dateTo, type }
  })
  return data
}

const print = async html => {
  const { data } = await Api({
    method: 'post',
    url: '/api/reporting/print',
    responseType: 'arraybuffer',
    data: { html }
  })
  return data
}

export const reportRepository = Object.freeze({
  getAgingReportData,
  print
})
