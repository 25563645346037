<script setup lang="ts">
import { onMounted, watch } from 'vue';

import { useDefaultDashboard } from '@/widgets/dashboard/composables/defaultDashboard';
import DashboardLayout from '@/widgets/dashboard/ui/DashboardLayout.vue';

import { DashboardType } from '../../widgets/dashboard/model/enum';

import RevenueDashboard from './ui/RevenueDashboard.vue';
import TechDashboard from './ui/TechDashboard.vue';

const { defaultDashboard, graphPermission, updateDashboardByPermission } =
	useDefaultDashboard();

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);
</script>

<template>
	<DashboardLayout>
		<template v-if="graphPermission">
			<RevenueDashboard v-if="defaultDashboard === DashboardType.revenue" />
			<TechDashboard v-if="defaultDashboard === DashboardType.tech" />
		</template>
		<TechDashboard v-else />
	</DashboardLayout>
</template>
