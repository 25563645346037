<template>
  <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="140px"
      size="small">
    <el-form-item prop="shopPhoneNumber" v-if='shopPhoneNumbers.length'>
      <span slot="label" class="undo-label-styles">Shop phone</span>
      <el-select
        placeholder="Select shop phone number"
        v-model="form.shopPhoneNumber"
        style="width: 100%"
        @focus='focusedShopPhoneSelect'
        >
        <el-option
          v-for="item in shopPhoneNumbers"
          :label="generateLabel(item)"
          :key="item.id"
          :value="item.phone_number">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="customerPhoneNumber">
      <span slot="label" class="undo-label-styles">Customer phone</span>
      <el-select
        placeholder="Select customer phone number"
        v-model="form.customerPhoneNumber"
        style="width: 100%"
        allow-create
        clearable
        default-first-option
        filterable>
        <el-option
          v-for="(item, key) in customerPhoneNumbers"
          :label="formatPhone(item.phone_number)"
          :key="key"
          :value="item.phone_number">
        </el-option>
      </el-select>
    </el-form-item>
    <consumer-select :consumerToCharge.sync="form.consumerToCharge" />
    <amount />
    <passthrough-fee v-if="displayPassthroughFeeOption" />
    <el-form-item prop="selectedTemplate">
      <span slot="label" class="undo-label-styles">Template</span>
      <el-select
        clearable
        placeholder="Select text message template (optional)"
        v-model="selectedTemplateId"
        style="width: 100%">
        <el-option
          v-for="item in templates"
          :label="item.name"
          :key="item.id"
          :value="item.id">
        </el-option>
      </el-select>
      or <a href="javascript:void(0)" @click="manageTemplates">Manage text message templates</a>
    </el-form-item>
    <el-form-item prop="body">
      <span slot="label" class="undo-label-styles">Message</span>
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 10}"
        placeholder="Text Body"
        v-model="form.body"
      />
    </el-form-item>
    <el-form-item style="word-break: break-word;">
      <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> A link to the customer payment portal will be automatically added in the end of the message.</p>
    </el-form-item>
    <el-form-item>
      <el-button
        class="mb-2 mr-2"
        type="primary"
        @click="handleSubmit"
        :loading="loading">
        Send
      </el-button>
      <el-button
        class="ml-0"
        plain
        @click="$emit('close')">
        Close
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { call, get } from 'vuex-pathify'
import Job from '@/scripts/objects/job'
import TwilioPhoneNumber from '@/scripts/objects/twilio_phone_number'
import TextMessageTemplate from '@/scripts/objects/text_message_template'
import { formatPhone } from '@/scripts/helpers/twilio.helpers'
import ConsumerSelect from './FormItem/ConsumerSelect.vue'
import Amount from './FormItem/Amount.vue'
import PassthroughFee from "./FormItem/PassthroughFee.vue";

export default {
  props: ['isActive'],
  components: {
    Amount,
    ConsumerSelect,
    PassthroughFee
  },
  data() {
    return {
      form: {
        consumerToCharge: null,
        shopPhoneNumber: '',
        customerPhoneNumber: '',
        body: ''
      },
      rules: {
        shopPhoneNumber: { required: true, message: 'Please select shop phone number', trigger: 'change' },
        customerPhoneNumber: { required: true, message: 'Please select customer phone number', trigger: 'change' },
        body: { required: true, message: 'Please enter a template body', trigger: 'change' },
        amount: { required: true, validator: (rule, value, callback) => {
          if (this.amount <= 0) {
            callback(new Error('Please enter a positive amount'))
          } else if (!this.amount) {
            callback(new Error('Please enter a valid amount'))
          } else {
            callback()
          }
        }, trigger: 'change' },
      },
      selectedTemplateId: '',
      templates: [],
      shopPhoneNumbers: [],
      customerPhoneNumbers: [],
      loading: false,
      showMarketingMessage: true
    }
  },
  computed: {
    amount: get("twilio/textMessages/jobPayment/amount"),
    balanceDetails: get('twilio/textMessages/jobPayment/balanceDetails'),
    displayPassthroughFeeOption: get("twilio/textMessages/jobPayment/displayPassthroughFeeOption"),
    includePassthroughFee: get("twilio/textMessages/jobPayment/includePassthroughFee"),
    jobId: get('twilio/textMessages/jobPayment/jobId'),
    passthroughTotal: get("twilio/textMessages/jobPayment/passthroughTotal"),
    paymentWasSentBefore: get('twilio/textMessages/jobPayment/paymentWasSentBefore'),
    shopId: get('twilio/textMessages/jobPayment/shopId'),
    total: get('twilio/textMessages/jobPayment/total'),
    typeId: get('twilio/textMessages/jobPayment/typeId')
  },
  methods: {
    openTextMessageTemplatesDialog: call('twilio/textMessages/templates/open'),
    setTextMessageTemplatesReferral: call('twilio/textMessages/templates/setReferral'),
    openTextMessageAdDialog: call('twilio/textMessages/ad/open'),
    setTextMessageAdReferral: call('twilio/textMessages/ad/setReferral'),
    setAmount: call('twilio/textMessages/jobPayment/setAmount'),
    formatPhone,
    manageTemplates () {
      this.visible = false
      this.openTextMessageTemplatesDialog()
      this.setTextMessageTemplatesReferral('job-payment')
    },
    showAd () {
      this.visible = false
      this.openTextMessageAdDialog()
      this.setTextMessageAdReferral('job-payment')
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.paymentWasSentBefore) {
            await this.$messageBox.confirm(
              'You already sent a payment request text message before for this job. Are you sure you want to send another one ?',
              'Warning',
              {
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    this.send()
                  }
                  done()
                }
              }
            )
          } else {
            this.send()
          }
        }
      } catch (err) {
        if (err.error === 'The message From/To pair violates a blacklist rule.') {
          this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
        }
        this.loading = false
      }
    },
    async send () {
      const passthroughTotal = this.includePassthroughFee ? this.passthroughTotal : 0
      await TextMessageTemplate.sendPayment({
        amount: this.amount,
        body: this.form.body,
        consumer_id: this.form.consumerToCharge.id,
        consumer_name: this.form.consumerToCharge.name,
        customerPhoneNumber: this.form.customerPhoneNumber,
        jobId: this.jobId,
        passthrough: passthroughTotal,
        shopPhoneNumber: this.form.shopPhoneNumber,
        total: this.total,
        typeId: this.typeId,
        type: 'sms'
      })
      this.$message.success('Job Payment link successfully sent.')
      this.visible = false
      this.loading = false
      this.$emit('sent')
    },
    async getTemplates () {
      try {
        const {data} = await this.$unum.job().get(`textMessageTemplate/allByType/1?shopId=${this.shopId}&type=4`)
        this.templates = data || []

        if (this.templates.length === 1) {
          this.selectedTemplateId = this.templates[0].id
          return
        }

        this.templates.forEach(template => {
          if (template.default === 1) {
            this.selectedTemplateId = template.id
          }
        })
      } catch {
        this.templates = []
      }
    },
    async getShopPhoneNumbers () {
      let shopPhoneNumbers = await TwilioPhoneNumber.getForJobMessaging(this.shopId)
      let defaultNumber = ''
      shopPhoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.default) {
          defaultNumber = phoneNumber.phone_number
        }
      })
      if (defaultNumber === '' && shopPhoneNumbers.length > 0) {
        defaultNumber = shopPhoneNumbers[0].phone_number
      }
      if (shopPhoneNumbers.length) {
        this.shopPhoneNumbers = shopPhoneNumbers
        this.form.shopPhoneNumber = defaultNumber
      } else {
        this.shopPhoneNumbers = [{phone_number: '4803516401', name: 'Glassbiller Default'}]
        this.form.shopPhoneNumber = '4803516401'
      }
    },
    async getCustomerPhoneNumbers () {
      this.customerPhoneNumbers = await Job.getCustomerPhoneNumbers(this.jobId)
      this.customerPhoneNumbers.forEach(phone => {
        if (phone.default === 1) {
          this.form.customerPhoneNumber = phone.phone_number
        }
      })
    },
    generateLabel (i) {
      const number = `${i.phone_number.substr(0, 3)}-${i.phone_number.substr(3, 3)}-${i.phone_number.substr(6)}`
      let label = `${number} - ${i.name}`
      if (i.hasOwnProperty('type')) {
        label += ` ( ${i.type} )`
      }
      return label
    },
    focusedShopPhoneSelect () {
      if (this.shopPhoneNumbers.length === 1 && this.shopPhoneNumbers[0].phone_number === '4803516401' && this.showMarketingMessage) {
        this.showMarketingMessage = false
        this.showAd()
      }
    },
  },
  mounted () {
    this.showMarketingMessage = true
    this.getTemplates()
    this.form = Object.assign({}, {...this.form, body:`Please click the link below to make your payment. Thank you for your business!`})
    this.getShopPhoneNumbers()
    this.getCustomerPhoneNumbers()
    this.loading = false
    this.$root.$on('refetchTemplates', this.getTemplates)
  },
  beforeDestroy: function () {
    this.$root.$off('refetchTemplates', this.getTemplates)
  },
  watch: {
    selectedTemplateId: async function (v) {
      if (v !== '') {
        this.form.body = (await TextMessageTemplate.generateMessageBody(v, this.jobId)).body
      } else {
        this.form.body = ''
      }
    },
    "form.consumerToCharge"(v) {
      this.setAmount(v.amount)
    },
  }
}
</script>
