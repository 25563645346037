<template>
  <el-form-item prop="amount" class="t-py-2">
    <div class="t-flex t-items-baseline t-px-6">
      <div class="t-text-gray-400 t-mr-2 t-text-base t-flex-grow t-text-right">
        Applied Total
      </div>
      <div class="t-text-xl t-font-medium t-text-gray-400">
        ${{ amount }}
      </div>
    </div>
    <div class="t-flex t-items-baseline t-px-6 justify-content-center">
      <div class="t-mr-2 t-text-base t-flex-grow t-text-right">
        <el-checkbox v-model="includePassthroughFee">Pass through fee</el-checkbox>
      </div>
      <div class="t-text-gray-400">
        ${{ passthroughTotal }}
      </div>
      <el-popover
        placement="right-end"
        trigger="hover"
        :content="feePopoverContent">
        <i slot="reference" class="el-icon-warning-outline t-ml-2"></i>
      </el-popover>
    </div>
    <div class="t-flex t-items-baseline t-px-6">
      <div class="t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right">
        Total
      </div>
      <div class="t-text-xl t-font-medium">${{ total }}</div>
    </div>
  </el-form-item>
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  props: ['includeFee'],
  computed: {
    amount: get("twilio/textMessages/jobPayment/amount"),
    includePassthroughFee: sync("twilio/textMessages/jobPayment/includePassthroughFee"),
    merchantPassthroughFees: get("twilio/textMessages/jobPayment/merchantPassthroughFees"),
    passthroughTotal: get("twilio/textMessages/jobPayment/passthroughTotal"),
    total: get("twilio/textMessages/jobPayment/total"),
    feePopoverContent: function () {
      const { fee_flat, fee_percentage } = this.merchantPassthroughFees[0]
      const feeFlat = parseFloat(fee_flat).toFixed(2).replace(/\.00$/, "")
      const feePercentage = parseFloat(fee_percentage).toFixed(2).replace(/\.00$/, "")

      return `${feePercentage}% + $${feeFlat}`
    }
  }
}
</script>

<style lang="scss">
.text-message-payment-dialog {
  span.el-checkbox__input.is-checked > span {
    background-color: gray;
    border-color: gray;
  }
  
  .el-checkbox__label {
    color: rgba(156, 163, 175, 1);
  }
  
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: rgba(156, 163, 175, 1);
  }
}
</style>
