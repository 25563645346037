<script setup lang="ts">
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/entities/user/lib/store';

import { reportsMenuConfig } from './lib/constants';

const i18n = useI18n();
const breadcrumbs = [{ label: i18n.t('reports') }];

const userStore = useUserStore();
const router = useRouter();
const searchQuery = ref('');

const config = computed(() => {
	const configArray = [];
	for (let i = 0; i < reportsMenuConfig.length; i++) {
		if (reportsMenuConfig[i].id === 'accounting-reports') {
			if (
				userStore.accountPackagesState.state &&
				userStore.accountPackagesState.state.length > 0
			) {
				configArray.push(reportsMenuConfig[i]);
			}
		} else if (reportsMenuConfig[i].id === 'miscellaneous') {
			const items = reportsMenuConfig[i].items;
			if (!userStore.checkUserCan('admin') && items) {
				for (let j = 0; j < items.length; j++) {
					if (items[j].id === 'nags-license' || items[j].id === 'edi') {
						items.splice(j, 1);
						j = items.length;
					}
				}
				configArray.push({ ...reportsMenuConfig[i], items });
			} else {
				configArray.push(reportsMenuConfig[i]);
			}
		} else {
			configArray.push(reportsMenuConfig[i]);
		}
	}
	return configArray;
});

const filteredConfig = computed(() => {
	if (!searchQuery.value.trim()) return config.value;
	return config.value
		.map(group => {
			const filteredItems = group.items.filter(item =>
				item.label.toLowerCase().includes(searchQuery.value.toLowerCase())
			);
			return { ...group, items: filteredItems };
		})
		.filter(group => group.items.length > 0);
});

const select = (item: {
	id: string;
	label: string;
	to?: string;
	command?: () => void;
}) => {
	if (item.to) router.push(item.to);
	else if (item.command) item.command();
};
</script>

<template>
	<div class="tw3-flex tw3-flex-col tw3-max-w-[1200px] tw3-w-full tw3-mx-auto">
		<div class="gb-header-cover gb-default-bg" />
		<div
			class="tw3-sticky tw3-flex tw3-items-center tw3-justify-between tw3-top-0 tw3-py-[1rem] gb-default-bg tw3-z-10"
		>
			<nav class="layout-breadcrumb tw3-text-gray-500 tw3-mr-4">
				<ol>
					<template
						v-for="breadcrumbRoute in breadcrumbs"
						:key="breadcrumbRoute"
					>
						<li
							class="tw3-cursor-pointer hover:tw3-opacity-40 tw3-transition-all"
						>
							{{ breadcrumbRoute.label }}
						</li>
					</template>
				</ol>
			</nav>
			<IconField class="tw3-w-full sm:tw3-w-1/2">
				<InputIcon class="pi pi-search" />
				<InputText
					v-model="searchQuery"
					class="tw3-w-full"
					placeholder="Search reports..."
					type="text"
				/>
			</IconField>
			<div class="sm:tw3-w-[51px]"></div>
		</div>
		<div
			class="tw3-flex tw3-flex-col lg:tw3-flex-row tw3-gap-0 lg:tw3-gap-4 tw3-p-4 tw3-pt-0 tw3-bg-white tw3-border-slate-200 tw3-rounded-md"
		>
			<div class="tw3-flex tw3-flex-col tw3-flex-1">
				<h2
					class="tw3-text-xl tw3-font-extrabold tw3-bg-white tw3-px-4 tw3-py-6"
				>
					{{ $t('allReports') }}
				</h2>
				<div
					v-if="!filteredConfig.length"
					class="tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center tw3-border-t"
				>
					<p class="tw3-text-xl tw3-text-center tw3-py-7">
						{{ $t('noData') }}
					</p>
				</div>
				<div
					v-for="group in filteredConfig"
					v-else
					:id="group.id"
					:key="group.id"
				>
					<div
						class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4 tw3-p-4 tw3-border-t tw3-border-b"
					>
						<div class="icon-component">
							<component :is="group.img" />
						</div>
						<div class="tw3-w-full tw3-flex tw3-flex-col tw3-gap-2">
							<div class="tw3-flex tw3-items-center tw3-gap-2">
								<h2 class="tw3-text-xl tw3-font-bold">
									{{ group.label }}
								</h2>
								<Tag>Reports: {{ group.items.length }}</Tag>
							</div>
							<div class="tw3-text-gray-500 tw3-text-sm">
								{{ group.desc }}
							</div>
						</div>
					</div>

					<div class="tw3-flex tw3-flex-col">
						<div
							v-for="item in group.items"
							:key="item.id"
							class="tw3-flex tw3-flex-col tw3-gap-2 tw3-px-8 tw3-py-4 tw3-border-b tw3-border-slate-200 last:tw3-border-b-0 tw3-bg-white hover:tw3-bg-transparent tw3-cursor-pointer tw3-transition-all"
							@click="select(item)"
						>
							<div class="tw3-flex tw3-items-center tw3-gap-2">
								<div class="tw3-text-blue-500 tw3-font-semibold tw3-text-lg">
									{{ item.label }}
								</div>
								<Tag severity="info">Filters: {{ item.filters }}</Tag>
							</div>
							<div class="tw3-text-gray-500 tw3-text-sm">
								{{ item.desc }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.gb-header-cover {
	position: fixed;
	top: 56px;
	width: 100%;
	height: 1rem;

	z-index: 10;
}

.gb-default-bg {
	background-color: #f2f2f2;
}

.layout-breadcrumb {
	ol {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		gap: 1rem;
		flex-wrap: wrap;

		li {
			font-weight: 600;
		}
	}
}

.gb-categories-block {
	scrollbar-width: none;
	-ms-overflow-style: none;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

.icon-component {
	display: flex;
	width: 2rem;
	height: 2rem;
	transition: 0.2s ease;

	svg {
		height: 100%;
		width: 100%;
	}
}

.category-hover:hover {
	.icon-component {
		color: #60a5fa;
	}
}
</style>
