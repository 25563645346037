import { useStorage } from '@vueuse/core';
import { computed } from 'vue';

import { useUserStore } from '@/entities/user/lib/store';
import { permissionCheck, permissions } from '@/entities/user/lib/util';

import { DEFAULT_DASHBOARD_KEY } from '../config/constants';
import { DashboardType } from '../model/enum';

export const useDefaultDashboard = () => {
	const userStore = useUserStore();

	const graphPermission = computed(() =>
		permissionCheck(permissions.GRAPH, userStore.user)
	);

	const defaultDashboard = useStorage<DashboardType>(
		DEFAULT_DASHBOARD_KEY + '_' + userStore.user.user.id,
		DashboardType.revenue
	);
	const updateDashboardByPermission = () => {
		if (
			!graphPermission.value &&
			defaultDashboard.value !== DashboardType.revenue
		) {
			defaultDashboard.value = DashboardType.revenue;
		}
	};

	return {
		graphPermission,
		defaultDashboard,
		updateDashboardByPermission
	};
};
