<script setup lang="ts">
import { onMounted, watch } from 'vue';

import { useDefaultDashboard } from '@/widgets/dashboard/composables/defaultDashboard';
import DashboardLayout from '@/widgets/dashboard/ui/DashboardLayout.vue';

import TechDashboard from './ui/TechDashboard.vue';

const { graphPermission, updateDashboardByPermission } = useDefaultDashboard();

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);
</script>

<template>
	<DashboardLayout>
		<TechDashboard />
	</DashboardLayout>
</template>
