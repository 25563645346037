import meet from '@/assets/img/meet.svg'
import quickbooks from '@/assets/img/quickbooks.svg'
export const headerConfig = [
  {
    key: '/jobs/new',
    label: 'New Job',
    can: 'job',
    cant: ['JOBVIEWONLY'],
    activePage: 'jobpage'
  },
  {
    key: '/jobs',
    label: 'Data',
    can: 'datatab',
    // link: true,
    children: [
      {
        can: 'datatabjob',
        key: '/jobs',
        label: 'Jobs'
      },
      {
        can: 'datatabcustomers',
        key: '/customers',
        label: 'Customers'
      },
      {
        can: 'datatabvendors',
        key: '/vendors',
        label: 'Vendors'
      },
      // {
      //   key: '/payments',
      //   label: 'Payments'
      // },
      // {
      //   key: '/purchaseorders',
      //   label: 'Purchase Orders'
      // },
    ]
  },
  {
    key: '/ar',
    label: 'AR/AP'
  },
  {
    can: 'reports',
    key: '/choose-reports',
    label: 'Reports'
  },
  {
    key: '/accounting',
    label: 'Accounting',
    can: 'admin',
    children: [
      {
        key: '/accounting/accounts',
        label: 'Chart of Accounts',
        useRouter: true
      },
      {
        key: '/accounting/transactions',
        label: 'Journal Entries',
        useRouter: true
      },
      {
        key: '/accounting/expenses',
        label: 'Expenses',
        useRouter: true
      },
      {
        key: '/accounting/report/balance-sheet',
        label: 'Balance sheet',
        useRouter: true
      },
      {
        key: '/accounting/report/income-statement',
        label: 'Income statement',
        useRouter: true
      },
      {
        key: '/accounting/customer/invoices',
        label: 'Customer Invoices',
        useRouter: true
      },
      {
        key: '/accounting/customer/payments',
        label: 'Customer Payments',
        useRouter: true
      },
      {
        key: '/accounting/customer/credits',
        label: 'Customer Credits',
        useRouter: true
      },
      {
        key: '/accounting/customer/balances',
        label: 'Customer Balances',
        useRouter: true
      },
      {
        key: '/accounting/settings',
        label: 'Accounting Settings',
        useRouter: true
      },
      {
        key: '/admin2/accounting/template',
        label: 'Starting Settings',
        useRouter: true
      },
      {
        key: '/admin2/accounting/profiles',
        label: 'Organization Profiles',
        useRouter: true
      },
    ]
  },
  {
    key: '/admin',
    label: 'Admin',
    can: 'account_manager',
    children: [
      {
        key: '/admin?tab=14',
        label: 'Accounts'
      },
      {
        key: '/admin?tab=16',
        label: 'Users'
      },
      {
        can: 'admin',
        key: '/admin?tab=17',
        label: 'TechSIDE Users'
      },
      {
        key: '/admin?tab=15',
        label: 'Shops'
      },
      {
        key: '/admin?tab=24',
        label: 'Account Packages'
      },
      {
        can: 'admin',
        key: '/admin?tab=25',
        label: 'Organizations'
      },
      {
        can: 'admin',
        key: '/admin?tab=23',
        label: 'NAGS Licenses'
      },
      {
        can: 'admin',
        key: '/fundingCenter',
        label: 'Funding Center'
      },
      {
        can: 'admin',
        key: '/shopAnalytics',
        label: 'Shop Analytics'
      },
      {
        key: '/admin?tab=26',
        label: 'Merge'
      }
    ]
  },
  {
    key: '/warehouse',
    label: 'Warehouse'
  },
  {
    key: 'https://glassbiller.gitbook.io/glassbiller/',
    link: true,
    label: 'Help'
  }
]

export const userMenuConfig = [
  {
    key: 'settings',
    icon: 'settings',
    label: 'Settings',
    can: ['account_manager'],
    action: 'openUserDialog'
  },
  {
    key: 'billing',
    icon: 'account_balance',
    label: 'Billing Details',
    can: ['account_manager'],
    action: 'openBillingDetails'
  },
  {
    key: 'quickbooks',
    image: quickbooks,
    label: 'QuickBooks',
    cant: ['techcommission'],
    display: 'allowQuickBooks',
    action: 'callQuickBooksDialog'
  },
  {
    key: 'https://www.youtube.com/@glassbiller46/videos',
    icon: 'video_library',
    label: 'Help Videos',
    link: true
  },
  {
    key: 'drafts',
    icon: 'folder_open',
    label: 'Drafts',
    action: 'openDraftsDialog'
  },
  {
    key: 'dashboard',
    icon: 'dashboard',
    label: 'Dashboard',
    action: 'callDashboardDialog'
  },
  {
    key: 'meet',
    image: meet,
    label: 'Join Meetings',
    action: 'joinMeeting'
  },
  {
    key: 'admin-utils',
    icon: 'handyman',
    label: 'Admin Utils',
    can: ['admin'],
    action: 'openAdminUtils'
  },
  {
    key: 'logout',
    icon: 'log_out',
    label: 'Log Out',
    action: 'logoutUser'
  }
]
