<template>
  <scheduler-job-card-list
    :title="leftListFilterTitle"
    :list="list"
    v-custom-infinite-scroll="{
      direction: isRowView ? 'horizontal' : 'vertical',
      loadMore: showMoreCards,
      target: '.job-card-list-container'
    }"
    @input="onDataChange"
    :minHeight="true"
  />
</template>

<script>
import { get, sync } from 'vuex-pathify'
import SchedulerJobCardList from './SchedulerJobCardList'
import * as R from 'ramda'

export default {
  components: { SchedulerJobCardList },
  data() {
    return {
      showCards: 1
    }
  },
  computed: {
    isRowView: get('sch_v3/isRowView'),
    leftListFilterTitle: get('sch_v3/leftListFilterTitle'),
    jobList: sync('sch_v3/jobList'),
    list() {
      return R.pipe(
        R.take(this.showCards * 15),
        R.map(R.objOf('job')),
        R.map(this.setVendors)
      )(this.jobList)
    }
  },
  methods: {
    showMoreCards() {
      this.showCards = this.showCards + 1
    },
    onDataChange(data) {
      const job = R.pipe(R.difference(this.list), R.path([0, 'job']))(data)
      if (job) {
        this.jobList = R.without([job])(this.jobList)
      }
    },
    setVendors(v) {
      return {
        ...v,
        vendors: v?.job?.vendors || []
      }
    }
  }
}
</script>
