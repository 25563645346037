var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "header-v2-menu-mobile": _vm.isMobile } },
    [
      _vm.isMobile
        ? _c(
            "div",
            {
              staticClass: "header-v2-menu-button",
              on: {
                click: function($event) {
                  _vm.menuOpen = !_vm.menuOpen
                }
              }
            },
            [_c("i", { staticClass: "material-icons md-menu" })]
          )
        : _vm._e(),
      _c(
        "el-menu",
        {
          ref: "menu",
          staticClass: "header-v2-menu",
          class: { "menu-open": _vm.menuOpen },
          attrs: {
            mode: _vm.isMobile ? "vertical" : "horizontal",
            "background-color": "#272727",
            "text-color": "#fff",
            "active-text-color": "#ffd04b",
            "default-active": _vm.defaultActive
          }
        },
        [
          _vm._l(_vm.config, function(item) {
            return [
              item.children
                ? [
                    !item.can || _vm.$role.can(item.can)
                      ? _c(
                          "el-submenu",
                          {
                            key: item.key,
                            attrs: {
                              index: item.key,
                              "popper-class": "header-v2-submenu"
                            }
                          },
                          [
                            _c("template", { slot: "title" }, [
                              item.link
                                ? _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "hover:t-no-underline t-text-white",
                                        attrs: { href: item.key },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            _vm.checkForUnpublishedDraft(item)
                                            _vm.menuOpen = false
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  ])
                                : _c("span", [_vm._v(_vm._s(item.label))])
                            ]),
                            _vm._l(item.children, function(child) {
                              return [
                                !child.can || _vm.$role.can(child.can)
                                  ? _c(
                                      "el-menu-item",
                                      {
                                        key: child.key,
                                        attrs: { index: child.key }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "hover:t-no-underline t-text-white t-block",
                                            attrs: { href: child.key },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                _vm.checkForUnpublishedDraft(
                                                  child
                                                )
                                                _vm.menuOpen = false
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(child.label) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                : [
                    item.link
                      ? _c(
                          "el-menu-item",
                          { key: item.key, attrs: { index: item.key } },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: item.key },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    _vm.openExternalLink(item.key)
                                    _vm.menuOpen = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          ]
                        )
                      : !item.can || _vm.$role.can(item.can)
                      ? _c(
                          "el-menu-item",
                          { key: item.key, attrs: { index: item.key } },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "hover:t-no-underline t-block",
                                attrs: { href: item.key },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    _vm.checkForUnpublishedDraft(item)
                                    _vm.menuOpen = false
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
            ]
          })
        ],
        2
      ),
      _c("draft-warning-dialog", {
        attrs: { visible: _vm.draftWarningVisible },
        on: {
          "update:visible": function($event) {
            _vm.draftWarningVisible = $event
          },
          discardDraft: _vm.discardDraft,
          leaveDraft: _vm.leaveDraft,
          publishDraft: _vm.publishDraft
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }