import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "tw3-max-w-[1440px] tw3-mx-auto tw3-w-full tw3-flex tw3-items-center tw3-justify-between tw3-mb-4 md:tw3-mb-8" }
const _hoisted_2 = { class: "layout-breadcrumb tw3-text-gray-500" }
const _hoisted_3 = {
  key: 0,
  class: "layout-breadcrumb-chevron"
}
const _hoisted_4 = { class: "tw3-flex tw3-justify-center" }
const _hoisted_5 = {
  key: 1,
  class: "tw3-ml-auto tw3-text-xs tw3-py-1 tw3-px-2 tw3-bg-gray-200 tw3-rounded-xl"
}

import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useMessages } from '@/shared/composables';

import { useDefaultDashboard } from '../composables/defaultDashboard';
import {
	HOME_PATH,
	REVENUE_DASHBOARD_PATH,
	TECHNICIAN_DASHBOARD_PATH
} from '../config/constants';
import { DashboardType } from '../model/enum';

import DefaultDashboardDialog from './modals/DefaultDashboardDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardLayout',
  setup(__props) {

const router = useRouter();
const { t } = useI18n();

const { showSuccess } = useMessages();
const { defaultDashboard, graphPermission } = useDefaultDashboard();

const selectDialog = ref(false);

const tabs = computed(() => [
	{ label: t('dashboard.index') },
	{
		label:
			(router.currentRoute.value.path === REVENUE_DASHBOARD_PATH ||
				(router.currentRoute.value.path === HOME_PATH &&
					defaultDashboard.value === DashboardType.revenue)) &&
			graphPermission.value
				? t('revenue')
				: t('technicians')
	}
]);
const menu = ref();
const menuItems = computed(() => [
	{
		label: t('dashboard.index'),
		items: [
			{
				label: t('technicians'),
				icon: 'pi pi-user',
				active: defaultDashboard.value === DashboardType.tech,
				command: () =>
					router.push(
						defaultDashboard.value === DashboardType.tech
							? HOME_PATH
							: TECHNICIAN_DASHBOARD_PATH
					)
			},
			...(graphPermission.value
				? [
						{
							label: t('revenue'),
							icon: 'pi pi-dollar',
							active: defaultDashboard.value === DashboardType.revenue,
							command: () =>
								router.push(
									defaultDashboard.value === DashboardType.revenue
										? HOME_PATH
										: REVENUE_DASHBOARD_PATH
								)
						},
						{
							label: t('selectDefault'),
							command: () => (selectDialog.value = true)
						}
					]
				: [])
		]
	}
]);

const toggle = (event: MouseEvent) => {
	menu.value.toggle(event);
};

const changeDefault = (v: DashboardType) => {
	if (defaultDashboard.value !== v) {
		defaultDashboard.value = v;

		if (router.currentRoute.value.path !== HOME_PATH) {
			router.push(HOME_PATH);
		}
	}
	showSuccess(t('dashboard.defaultChanged'));
};

return (_ctx: any,_cache: any) => {
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(DefaultDashboardDialog, {
      show: selectDialog.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((selectDialog).value = $event)),
      selected: _unref(defaultDashboard),
      onChangeDefault: changeDefault
    }, null, 8, ["show", "selected"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("ol", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (breadcrumbRoute, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: breadcrumbRoute }, [
              _createElementVNode("li", null, _toDisplayString(breadcrumbRoute.label), 1),
              (i !== tabs.value.length - 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, " / "))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_unref(graphPermission))
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 0,
              "aria-controls": "dashboard_overlay_menu",
              "aria-haspopup": "true",
              icon: "pi pi-cog",
              type: "button",
              onClick: toggle
            }))
          : _createCommentVNode("", true),
        (_unref(graphPermission))
          ? (_openBlock(), _createBlock(_unref(Menu), {
              key: 1,
              id: "dashboard_overlay_menu",
              ref_key: "menu",
              ref: menu,
              model: menuItems.value,
              popup: ""
            }, {
              item: _withCtx(({ item, props }) => [
                _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({ class: "tw3-flex tw3-items-center" }, props.action), [
                  (item.icon)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(item.icon)
                      }, null, 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(item.label), 1),
                  (item.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Default "))
                    : _createCommentVNode("", true)
                ], 16)), [
                  [_directive_ripple]
                ])
              ]),
              _: 1
            }, 8, ["model"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})