import { i18n } from '@/shared/i18n';

import { TimeRange } from '../model/types';

export const DEFAULT_DASHBOARD_KEY = 'default-dashboard';

export const HOME_PATH = '/';
export const REVENUE_DASHBOARD_PATH = '/revenue';
export const TECHNICIAN_DASHBOARD_PATH = '/technician';

export const timeRangeModes = [
	{ name: i18n.global.t('time.day'), code: TimeRange.DAY },
	{ name: i18n.global.t('time.week'), code: TimeRange.WEEK },
	{ name: i18n.global.t('time.month'), code: TimeRange.MONTH },
	{ name: i18n.global.t('time.year'), code: TimeRange.YEAR }
];
