var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar-job-card",
      class: { hidden: _vm.item.hidden },
      style: _vm.cardStyle,
      on: {
        click: _vm.card.action,
        dragstart: function($event) {
          return _vm.itemDrag(_vm.item)
        },
        dragend: function($event) {
          return _vm.itemDrag()
        }
      }
    },
    [
      _vm.color
        ? _c("div", {
            staticClass: "left-border",
            style: { background: _vm.color }
          })
        : _vm._e(),
      _c("div", [
        _c("div", { staticClass: "content" }, [
          _c("div", [_vm._v(" " + _vm._s(_vm.card.title) + " ")]),
          _c(
            "div",
            [
              _vm.card.extra
                ? _c("span", { staticClass: "extra", style: _vm.cardStyle }, [
                    _vm._v(" " + _vm._s(_vm.card.extra) + " ")
                  ])
                : _vm._e(),
              _c("v-icon", {
                staticClass: "icon",
                style: _vm.cardStyle,
                attrs: { name: _vm.card.icon, scale: "0.7" }
              })
            ],
            1
          )
        ]),
        _vm.showInstallLocation && _vm.card.installCity && _vm.card.installState
          ? _c(
              "span",
              [
                _c("v-icon", {
                  staticClass: "t-mb-1",
                  attrs: { name: "map-marker-alt", scale: "0.7" }
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.card.installCity) +
                    ", " +
                    _vm._s(_vm.card.installState) +
                    " "
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }