var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id:
          _vm.location === "side"
            ? _vm.open
              ? "sidebar-open-column"
              : "sidebar-closed-column"
            : ""
      }
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.location == "side" ? "d-lg-inline d-none" : "d-inline d-lg-none"
          ]
        },
        [
          _vm.location === "side"
            ? _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.open,
                      expression: "!open"
                    }
                  ],
                  staticClass: "mt-1",
                  staticStyle: { margin: "0px" }
                },
                [
                  _c("div", { staticStyle: { "text-align": "left" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-btn open-it",
                        staticStyle: { "background-color": "red" },
                        on: {
                          click: function($event) {
                            return _vm.openButtonClicked()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-angle-double-up" }),
                        _vm._v(" OPEN ")
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open"
                }
              ],
              staticClass: "mt-3"
            },
            [
              _vm.location === "side"
                ? _c("div", { staticClass: "btn-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-btn close-it",
                        staticStyle: { "background-color": "red" },
                        on: {
                          click: function($event) {
                            return _vm.closeButtonClicked()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-angle-double-left" }),
                        _vm._v(" Close ")
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: {
                    "btn-wrap": _vm.location === "side",
                    "col-6": _vm.location === "top",
                    "col-md-4": _vm.location === "top"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { id: "text-messages-button" },
                      on: { click: _vm.openTextMessagesDialog }
                    },
                    [
                      _c(
                        "el-badge",
                        {
                          attrs: {
                            value: _vm.unreadTextMessageThreadsCount,
                            max: 99,
                            hidden:
                              _vm.unreadTextMessageThreadsCount === 0
                                ? true
                                : false,
                            type: "primary",
                            id: "unread-threads-badge"
                          }
                        },
                        [
                          _vm.$store.state.user.has_unread_text_message
                            ? _c(
                                "span",
                                { attrs: { id: "new-thread-badge" } },
                                [_vm._v("new")]
                              )
                            : _vm._e(),
                          _c("img", {
                            attrs: {
                              src: require("../../assets/img/sms-bubble.svg")
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "btn-wrap": _vm.location === "side",
                    "col-6": _vm.location === "top",
                    "col-md-4": _vm.location === "top"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-small-square btn-gray mb-1 btn-text-error",
                      on: { click: _vm.supportClicked }
                    },
                    [
                      _vm._v(" " + _vm._s("Support ")),
                      _c("span", { attrs: { id: "chatnow" + _vm.location } })
                    ]
                  )
                ]
              ),
              _vm.permissionCheck(_vm.permissions.SCHEDULER, _vm.$store.state)
                ? _c(
                    "div",
                    {
                      class: {
                        "btn-wrap": _vm.location === "side",
                        "col-6": _vm.location === "top",
                        "col-md-4": _vm.location === "top"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value:
                                _vm.location === "top" ? "navbarCollapse" : "",
                              expression:
                                "location === 'top' ? 'navbarCollapse' : ''"
                            }
                          ],
                          staticClass:
                            "btn-square btn-gray mb-1 btn-text-warning",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openSchedulerDialog($event)
                            }
                          }
                        },
                        [
                          _vm.$store.state.userTechsideCount > 0
                            ? _c(
                                "span",
                                { staticClass: "notification right green" },
                                [_vm._v(_vm._s(_vm.computedTechsideCount))]
                              )
                            : _vm._e(),
                          _c("font-awesome-icon", {
                            attrs: { icon: _vm.faCalendar }
                          }),
                          _vm._v(" Scheduler")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  class: {
                    "btn-wrap": _vm.location === "side",
                    "col-6": _vm.location === "top",
                    "col-md-4": _vm.location === "top"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn-square btn-gray mb-1 btn-text-gray btn-text-blue",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.quotesDialogOpen($event)
                        }
                      }
                    },
                    [
                      _vm.$store.state.userQuoteCount > 0
                        ? _c(
                            "span",
                            { staticClass: "notification right yellow" },
                            [_vm._v(_vm._s(_vm.computedQuotesCount))]
                          )
                        : _vm._e(),
                      _vm.$store.state.userFollowupQuoteCount > 0
                        ? _c(
                            "span",
                            { staticClass: "notification bottom yellow" },
                            [_vm._v("Follow Up")]
                          )
                        : _vm._e(),
                      _c("font-awesome-icon", { attrs: { icon: _vm.faBolt } }),
                      _vm._v(" Quotes")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "btn-wrap": _vm.location === "side",
                    "col-6": _vm.location === "top",
                    "col-md-4": _vm.location === "top"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.location === "top" ? "navbarCollapse" : "",
                          expression:
                            "location === 'top' ? 'navbarCollapse' : ''"
                        }
                      ],
                      staticClass: "btn-square btn-gray mb-1 btn-text-success",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openMobileDialog($event)
                        }
                      }
                    },
                    [
                      _vm.$store.state.userSalesideJobCount > 0
                        ? _c(
                            "span",
                            { staticClass: "notification right blue" },
                            [_vm._v(_vm._s(_vm.computedSalesideJobCount))]
                          )
                        : _vm._e(),
                      _vm.$store.state.userSalesideUnreadMessageCount > 0
                        ? _c(
                            "span",
                            { staticClass: "notification left light-blue" },
                            [
                              _c("i", {
                                staticClass: "el-icon-message",
                                staticStyle: { "font-size": "16px" }
                              })
                            ]
                          )
                        : _vm._e(),
                      _c("font-awesome-icon", {
                        attrs: { icon: _vm.faMobile }
                      }),
                      _vm._v(" Mobile ")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  class: {
                    "btn-wrap": _vm.location === "side",
                    "col-6": _vm.location === "top",
                    "col-md-4": _vm.location === "top"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: "faxInboxModal",
                          expression: "'faxInboxModal'"
                        },
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.location === "top" ? "navbarCollapse" : "",
                          expression:
                            "location === 'top' ? 'navbarCollapse' : ''"
                        }
                      ],
                      staticClass:
                        "btn-square btn-gray mb-1 btn-text-dark-blue",
                      attrs: { href: "javascript:void(0)" }
                    },
                    [
                      _vm.$store.state.unreadFaxCount > 0
                        ? _c(
                            "span",
                            { staticClass: "notification right white" },
                            [_vm._v(_vm._s(_vm.computedUnreadFaxCount))]
                          )
                        : _vm._e(),
                      _c("font-awesome-icon", { attrs: { icon: _vm.faFax } }),
                      _vm._v(" Fax")
                    ],
                    1
                  )
                ]
              ),
              _vm.showGlaxis
                ? _c(
                    "div",
                    {
                      class: {
                        "btn-wrap": _vm.location === "side",
                        "col-6": _vm.location === "top",
                        "col-md-4": _vm.location === "top"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: "glaxisModal",
                              expression: "'glaxisModal'"
                            },
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value:
                                _vm.location === "top" ? "navbarCollapse" : "",
                              expression:
                                "location === 'top' ? 'navbarCollapse' : ''"
                            }
                          ],
                          staticClass:
                            "btn-square btn-gray mb-1 btn-text-purple",
                          attrs: { href: "javascript:void(0)" }
                        },
                        [
                          _vm.$store.state.glaxisWorkAssignmentsCount > 0
                            ? _c(
                                "span",
                                { staticClass: "notification right orange" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.computedGlaxisWorkAssignmentsCount
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("font-awesome-icon", {
                            attrs: { icon: _vm.faComments }
                          }),
                          _vm._v(" Glaxis")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c("mobile-inbox-dialog", {
        attrs: { parentVisible: _vm.mobileInboxVisible },
        on: {
          "update:parentVisible": function($event) {
            _vm.mobileInboxVisible = $event
          },
          "update:parent-visible": function($event) {
            _vm.mobileInboxVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }