<template>
  <el-form-item prop="amount">
    <span slot="label" class="undo-label-styles">Requested Amount</span>
    <el-input v-model="amount" @blur='currencyBlur'>
      <span class="t-ml-2" slot="prefix">$</span>
    </el-input>
  </el-form-item>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  computed: {
    amount: sync("twilio/textMessages/jobPayment/amount")
  },
  methods: {
    currencyBlur () {
      if (isNaN(this.amount) || !this.amount) {
        this.amount = null
      } else {
        this.amount = parseFloat(this.amount).toFixed(2)
      }
    }
  }
}
</script>
