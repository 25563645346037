import ObjectTracker from '@/scripts/objects/objectTracker'
import store from '@/store/store'
import Api from '@/services/Api'

export default class Shop extends ObjectTracker {
  id = -1
  created = -1
  status = 'active'
  name = ''
  phone = ''
  fax = ''
  email = ''
  web = ''
  address = ''
  address_city = ''
  address_state = ''
  address_zip = ''
  disclaimer = ''
  safelite_edi_number = ''
  quest_edi_number = ''
  lynx_edi_number = ''
  modified = -1
  notes = ''
  ssn_vs_tax_id = ''
  ssn_or_tax_id = -1
  qbo_key = ''
  is_internal_number_auto_increment = -1
  internal_number_start = -1
  authorizenet_api_login_id = ''
  authorizenet_transaction_key = ''
  authorizenet_public_client_key = ''
  saleside_warranty_text = ''
  saleside_terms_and_conditions = ''
  current_order_id = 1
  current_order_part_id = 1
  zoho_contact_id = 0
  zoho_lock_shop_for_order = 0
  zoho_balance_due = 0.00
  zoho_pricebook_id = ''
  logo = ''
  mygrant_account_id = ''
  mygrant_default_warehouse_id = ''
  igc_account_id = ''
  shop_special_pdf_field = ''
  pgw_customer_number = ''
  include_tax_id_on_pdf = 1
  condensed_pdf_default = 1
  podium_location_id = ''
  podium_from_address = ''
  last_qb_export_type = ''
  colorado_tax_option = 0
  copy_salesrep_notes = 0
  rtr_business_id = null
  rtr_company_name = null
  review_type = 'podium'
  customer_address = ''
  customer_city = ''
  customer_state = ''
  customer_zip = ''
  qbo_refresh_token = ''
  qbo_refresh_token_expires = -1
  qbo_realm_id = ''
  customer_logo = ''
  auto_update_aob = 0
  auto_qb_export_invoices = -1
  auto_qb_export_payments = -1
  version_pdf_default = 'redesign'
  customer_invoice_message = ''
  customer_workorder_message = ''
  customer_quote_message = ''
  ar_hide_tags = 0
  show_glaxis_inbox = 0
  adhesive_default = 'standard'
  tsign_version = 'withPrices'
  twilio_account = {}
  twilio_phone_numbers = []
  accounting_package_id = -1
  podium_api_key = ''
  inventory_tracking = 0
  inventory_costing_method = 'fifo'
  default_job_confirmation_phone_number_id = null
  right_part_access = 0
  address_id = null
  g_address = null
  emails = []
  notificationEmails = []
  notificationEmails_leads = []
  inspection_initials = []
  is_funded = 0
  funding_start_date = null
  is_funding_pending = null
  pdf_nags_description = 0
  default_invoice_tier = null
  pdf_show_csr = 1
  pdf_show_tech = 1
  pdf_show_salesrep = 1
  pdf_show_salesource = 1
  pdf_show_location = 1
  pdf_show_remittance = 0
  vendor_credentials = []
  pdfOptions = []
  square_merchant_id = ''
  square_currency = ''
  clover_merchant_id = ''
  clover_currency = ''
  clover_api_key = ''
  shop_services_config = {}
  pdf_bill_to_customer = 0
  default_rates = -1
  default_install_context = null
  organization_id = null
  pdf_default = 'withPrices'
  auto_sms_payment_receipt = 1
  glaxis_company_id = null
  glaxis_shop_name = null
  shop_device_clovers = []
  created_consumers_auto_set_install_address = 1
  vendor_credentials = []
  square_location_id = null
  shop_confirmation_settings = {
    commercial: false,
    same_day: true,
    send: false,
    send_sunday: false,
    time: null
  }
  royalty_percentage = 0
  merchant_passthrough_fees = []

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getShop (shopId) {
    for (var i = 0; i < store.state.shops.length; i++) {
      if (store.state.shops[i].id === shopId) {
        return store.state.shops[i]
      }
    }
  }

  static getShops (callbackFunction, errorCallbackFunction = null) {
    Api({ url: '/api/shops' }).then(res => {
      callbackFunction(res)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static async getShopsForUser () {
    return await Api({ url: `/api/shops` })
  }

  static async getSquareLocationsForShop (shopId) {
    return await Api({ url: `/api/shop/${shopId}/square/locations` })
  }

  static getShopFull (shopId, callbackFunction, errorCallbackFunction = null) {
    Api({ url: `/api/shop/${shopId}/details` }).then(res => {
      callbackFunction(res)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateShopAccountingPackageSettings (shopId, shopAccountingPackageSettings, callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/accounting_package/shop/${shopId}/shop_accounting_package_settings`, shopAccountingPackageSettings).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  createUpdate (callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/shop/${this.id}`, this).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateShopAutoQBOExportInvoices (shopId, auto_qb_export_invoices, callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/shop/${shopId}/updateQBOExport`, {auto_qb_export_invoices: auto_qb_export_invoices}).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateShopAutoQBOExportPayments (shopId, auto_qb_export_payments, callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/shop/${shopId}/updateQBOExport`, {auto_qb_export_payments: auto_qb_export_payments}).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static updateShopARSettings (shopId, ar_hide_tags, callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/shop/${shopId}/updateARSettings`, {ar_hide_tags: ar_hide_tags}).then(res => {
      callbackFunction(res)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  disconnectFromQBO (callbackFunction, errorCallbackFunction = null) {
    Api.post(`/api/shop/${this.id}/disconnectFromQBO`, this).then(res => {
      callbackFunction(res.data)
    }, error => {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  async adminCreateShopItems () {
    return Api.post(`/api/shop/${this.id}/adminCreateShopItems`)
  }

  async adminUpdateNagsItems () {
    return Api.post(`/api/shop/${this.id}/adminBulkUpdateInventory/true`)
  }

  async adminUpdateAllItems () {
    return Api.post(`/api/shop/${this.id}/adminBulkUpdateInventory/false`)
  }

  static async updateShopCauseOfLossTypes (shopId, newVal) {
    return Api.post(`/api/shop/${shopId}/updateShopCauseOfLossTypes`, {newVal})
  }

  static async updateShopTimeSpans (shopId, newVal) {
    return Api.post(`/api/shop/${shopId}/updateShopTimeSpans`, {newVal})
  }

  static createVendorCredentials ({
    accountNumber,
    isDefault = false,
    name,
    password = null,
    shopId,
    username = null,
    vendor
  }) {
    return Api.post(`/api/shop/${shopId}/createVendorCredentials`, {
      accountNumber,
      isDefault,
      name,
      password,
      username,
      vendor
    })
  }

  static updateVendorCredentials ({
    accountNumber,
    id,
    isDefault = false,
    name,
    password = null,
    shopId,
    username = null,
    vendor
  }) {
    return Api.put(`/api/shop/createVendorCredentials/${id}`, {
      accountNumber,
      isDefault,
      name,
      password,
      shopId,
      username,
      vendor
    })
  }

  static async getVendorCredentials ({ shopId }) {
    const { data } = await Api.get(`/api/shop/${shopId}/getVendorCredentials`)
    return data
  }

  static async deleteVendorCredentials ({ id }) {
    await Api.delete(`/api/shop/deleteVendorCredentials/${id}`)
  }

  static async setDefaultVendorCredentials ({ id }) {
    await Api.post('/api/shop/vendorCredentials/setDefault', { id })
  }

  static async getGlassBillerShopDefaultPdfOptions () {
    return await Api.get('/api/shop/pdfOptions/getDefault')
  }

  static async getAnalyticsShops (showHidden, sortBy, order, loadPosition) {
    return await Api.get('/api/shop/analytics', {params: {showHidden, sortBy, order, loadPosition}})
  }
  static async getAnalyticsShopsFilter (showHidden, sortBy, order, loadPosition, searchInput) {
    return await Api.get('/api/shop/analytics/filtershops', {params: {showHidden, sortBy, order, loadPosition, searchInput}})
  }

  static async getAnalyticsShopDetail (shopId) {
    return await Api.get(`/api/shop/analytics/${shopId}`)
  }

  static async updateShopHidden (shopId, show) {
    return await Api.post(`/api/shop/analytics/${shopId}`, {show})
  }

  async updateAuthorizeNet (values) {
    return await Api.post(`/api/shop/${this.id}/authorizenetupdate`, {values})
  }

  async disconnectClover (shopId) {
    return await Api.patch(`/api/shop/${shopId}/cloverConnector/disconnect`)
  }

  async getUnconnectedDevices (shopId) {
    return await Api.get(`/api/clover/${shopId}/unconnected-devices`)
  }

  async addShopCloverDevice (shopId, deviceData) {
    return await Api.post(`/api/shop/${shopId}/clover/addShopDevice`, deviceData)
  }

  async removeShopCloverDevice (shopId, deviceData) {
    return await Api.post(`/api/shop/${shopId}/clover/removeShopDevice`, deviceData)
  }

  static async getAvailableDevices (shopId) {
    return await Api.get(`/api/shop/${shopId}/available-devices`)
  }

  static async getMerchantPassthroughFees (shopId) {
    return await Api.get(`/api/shop/${shopId}/merchantPassthroughFees`)
  }

  static async getDeviceCredentials (shopId) {
    return await Api.get(`/api/shop/${shopId}/clover/device-credentials`)
  }

  static async startSquareCheckout (shopId, paymentData) {
    return await Api.post(`/api/shop/${shopId}/square/createCheckout`, paymentData)
  }

  static async cancelSquareCheckout (shopId, checkout) {
    return await Api.post(`/api/shop/${shopId}/square/cancelCheckout`, checkout)
  }

  static async saveSquareDevices (shopId, devices) {
    return await Api.post(`/api/shop/${shopId}/square/saveDevices`, devices)
  }

  static async forgetSquareDevice (shopId, deviceId) {
    return await Api.post(`api/shop/${shopId}/square/forgetDevice/${deviceId}`)
  }

  static async getConnectedSquareDevices (shopId) {
    return await Api.get(`/api/shop/${shopId}/square/connectedDevices`)
  }

  static async getUnconnectedSquareDevices (shopId) {
    return await Api.get(`/api/shop/${shopId}/square/unconnectedDevices`)
  }

  static async createDeviceCode (shopId, deviceObj) {
    return await Api.post(`/api/shop/${shopId}/square/createDeviceCode`, deviceObj)
  }
}
