<script setup lang="ts">
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useMessages } from '@/shared/composables';

import { useDefaultDashboard } from '../composables/defaultDashboard';
import {
	HOME_PATH,
	REVENUE_DASHBOARD_PATH,
	TECHNICIAN_DASHBOARD_PATH
} from '../config/constants';
import { DashboardType } from '../model/enum';

import DefaultDashboardDialog from './modals/DefaultDashboardDialog.vue';

const router = useRouter();
const { t } = useI18n();

const { showSuccess } = useMessages();
const { defaultDashboard, graphPermission } = useDefaultDashboard();

const selectDialog = ref(false);

const tabs = computed(() => [
	{ label: t('dashboard.index') },
	{
		label:
			(router.currentRoute.value.path === REVENUE_DASHBOARD_PATH ||
				(router.currentRoute.value.path === HOME_PATH &&
					defaultDashboard.value === DashboardType.revenue)) &&
			graphPermission.value
				? t('revenue')
				: t('technicians')
	}
]);
const menu = ref();
const menuItems = computed(() => [
	{
		label: t('dashboard.index'),
		items: [
			{
				label: t('technicians'),
				icon: 'pi pi-user',
				active: defaultDashboard.value === DashboardType.tech,
				command: () =>
					router.push(
						defaultDashboard.value === DashboardType.tech
							? HOME_PATH
							: TECHNICIAN_DASHBOARD_PATH
					)
			},
			...(graphPermission.value
				? [
						{
							label: t('revenue'),
							icon: 'pi pi-dollar',
							active: defaultDashboard.value === DashboardType.revenue,
							command: () =>
								router.push(
									defaultDashboard.value === DashboardType.revenue
										? HOME_PATH
										: REVENUE_DASHBOARD_PATH
								)
						},
						{
							label: t('selectDefault'),
							command: () => (selectDialog.value = true)
						}
					]
				: [])
		]
	}
]);

const toggle = (event: MouseEvent) => {
	menu.value.toggle(event);
};

const changeDefault = (v: DashboardType) => {
	if (defaultDashboard.value !== v) {
		defaultDashboard.value = v;

		if (router.currentRoute.value.path !== HOME_PATH) {
			router.push(HOME_PATH);
		}
	}
	showSuccess(t('dashboard.defaultChanged'));
};
</script>

<template>
	<div>
		<DefaultDashboardDialog
			v-model:show="selectDialog"
			:selected="defaultDashboard"
			@change-default="changeDefault"
		/>
		<div
			class="tw3-max-w-[1440px] tw3-mx-auto tw3-w-full tw3-flex tw3-items-center tw3-justify-between tw3-mb-4 md:tw3-mb-8"
		>
			<nav class="layout-breadcrumb tw3-text-gray-500">
				<ol>
					<template v-for="(breadcrumbRoute, i) in tabs" :key="breadcrumbRoute">
						<li>{{ breadcrumbRoute.label }}</li>
						<li v-if="i !== tabs.length - 1" class="layout-breadcrumb-chevron">
							/
						</li>
					</template>
				</ol>
			</nav>
			<div class="tw3-flex tw3-justify-center">
				<Button
					v-if="graphPermission"
					aria-controls="dashboard_overlay_menu"
					aria-haspopup="true"
					icon="pi pi-cog"
					type="button"
					@click="toggle"
				/>
				<Menu
					v-if="graphPermission"
					id="dashboard_overlay_menu"
					ref="menu"
					:model="menuItems"
					popup
				>
					<template #item="{ item, props }">
						<div
							v-ripple
							class="tw3-flex tw3-items-center"
							v-bind="props.action"
						>
							<span v-if="item.icon" :class="item.icon" />
							<span>{{ item.label }}</span>
							<span
								v-if="item.active"
								class="tw3-ml-auto tw3-text-xs tw3-py-1 tw3-px-2 tw3-bg-gray-200 tw3-rounded-xl"
							>
								Default
							</span>
						</div>
					</template>
				</Menu>
			</div>
		</div>
		<slot />
	</div>
</template>

<style scoped lang="scss">
.layout-breadcrumb {
	ol {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		gap: 1rem;
		flex-wrap: wrap;

		li {
			font-weight: 600;
		}
	}
}
</style>
