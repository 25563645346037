var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        top: "120px",
        width: "1000px",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        "custom-class": "add-payment-dialog t-font-sans"
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("i", { staticClass: "el-icon-bank-card" }),
          _c("span", { staticClass: "t-ml-3" }, [
            _vm._v(" Add payment "),
            _vm.consumer
              ? _c("span", [
                  _vm._v(
                    " from " +
                      _vm._s(
                        _vm.consumer.is_insurance || _vm.consumer.is_commercial
                          ? _vm.consumer.company_name
                          : _vm.consumer.first_name +
                              " " +
                              _vm.consumer.last_name
                      ) +
                      " "
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "t-pt-6" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "t-overflow-y-auto",
              staticStyle: { "max-height": "700px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "165px",
                "hide-required-asterisk": "",
                size: "medium"
              }
            },
            [
              _c("div", { staticClass: "t-flex" }, [
                _c(
                  "div",
                  { staticClass: "t-w-108" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "type" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "undo-label-styles",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [_vm._v("Type")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "t-w-full",
                            model: {
                              value: _vm.form.type,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "Payment", value: "Payment" }
                            }),
                            _c("el-option", {
                              attrs: {
                                label: "Adjustment",
                                value: "Adjustment"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "date" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "undo-label-styles",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [_vm._v("Date")]
                        ),
                        _c("el-date-picker", {
                          staticClass: "t-w-full",
                          attrs: { type: "date", placeholder: "Pick a date" },
                          model: {
                            value: _vm.form.date,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "date", $$v)
                            },
                            expression: "form.date"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "xActionType" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "undo-label-styles",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [_vm._v(" X-action Type ")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "t-w-full",
                            attrs: { placeholder: "Select X-action Type" },
                            model: {
                              value: _vm.form.xActionType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "xActionType", $$v)
                              },
                              expression: "form.xActionType"
                            }
                          },
                          _vm._l(_vm.xActionTypes[_vm.form.type], function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item,
                              attrs: {
                                label: item,
                                value: item,
                                disabled:
                                  item === "Card" &&
                                  !_vm.authorizeEnabled &&
                                  !_vm.squareEnabled &&
                                  !_vm.cloverEnabled
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.form.xActionType === "Card" && _vm.paymentProcessor
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "undo-label-styles",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [_vm._v(" Payment Processor ")]
                            ),
                            _vm.paymentProcessor === "authorize"
                              ? _c("el-input", {
                                  attrs: {
                                    value: "Authorize.Net",
                                    readonly: ""
                                  }
                                })
                              : _vm._e(),
                            _vm.paymentProcessor === "square"
                              ? _c("el-input", {
                                  attrs: { value: "Square", readonly: "" }
                                })
                              : _vm._e(),
                            _vm.paymentProcessor === "clover"
                              ? _c("el-input", {
                                  attrs: { value: "Clover", readonly: "" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.xActionType !== "Card"
                      ? _c(
                          "el-form-item",
                          { attrs: { prop: "xActionNumber" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "undo-label-styles",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [_vm._v(" X-action Number ")]
                            ),
                            _c("el-input", {
                              staticClass: "t-w-full",
                              attrs: { placeholder: "Enter X-action Number" },
                              model: {
                                value: _vm.form.xActionNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "xActionNumber", $$v)
                                },
                                expression: "form.xActionNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.shopHasQbo && _vm.form.type === "Payment"
                      ? _c("el-form-item", [
                          _c(
                            "span",
                            {
                              staticClass: "undo-label-styles",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [_vm._v(" Deposit to: ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.truncateString(_vm.defaultQboAccount)
                              ) +
                              " "
                          ),
                          _vm.adminCheck() || _vm.accountManagerCheck()
                            ? _c(
                                "span",
                                { staticStyle: { "margin-left": "15px" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onOpenQboMapping(
                                            _vm.shopId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("QBO Mapping")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "t-flex-grow" }, [
                  _vm.form.xActionType !== "Card" &&
                  !_vm.squareTerminalConnecting &&
                  !_vm.cloverTerminalConnecting
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "t-flex t-justify-center" },
                          _vm._l(_vm.processorCards, function(card, index) {
                            return _c(
                              "div",
                              {
                                key: card.label,
                                class: {
                                  "device-card-border": index > 0,
                                  "t-h-36": index === 1
                                }
                              },
                              [
                                _c("device-card", {
                                  attrs: {
                                    label: card.label,
                                    beta: card.betaFlag,
                                    device: card.device
                                  },
                                  on: { selected: card.action }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm.canChooseDevice &&
                        !_vm.squareTerminalConnecting &&
                        !_vm.cloverTerminalConnecting &&
                        _vm.form.xActionType !== "Card"
                          ? _c(
                              "div",
                              {
                                staticClass: "t-text-right",
                                class: {
                                  "t-pr-[120px]": _vm.processorCards.length > 1,
                                  "t-pr-[208px]": _vm.processorCards.length <= 1
                                }
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: { trigger: "click" },
                                    on: { command: _vm.choseDevice }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "dropdown-link" },
                                      [
                                        _vm._v(" Choose device"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      _vm._l(
                                        _vm.availableProcessorDevices,
                                        function(device) {
                                          return _c(
                                            "el-dropdown-item",
                                            {
                                              key: device.id,
                                              attrs: { command: device }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(device.name) + " "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _c(
                        "div",
                        { staticClass: "t-flex t-flex-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "t-mb-2 t-px-6" },
                            [
                              _vm.form.xActionType === "Card"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-back",
                                        size: "mini",
                                        plain: "",
                                        round: ""
                                      },
                                      on: { click: _vm.backClicked }
                                    },
                                    [_vm._v(" Back ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.form.xActionType === "Card" &&
                          _vm.paymentProcessor === "authorize" &&
                          _vm.authorizeScriptLoaded
                            ? _c("CardForm", {
                                staticClass: "t-px-6",
                                attrs: {
                                  name: _vm.card.name,
                                  number: _vm.card.number,
                                  month: _vm.card.month,
                                  year: _vm.card.year,
                                  cvv: _vm.card.cvv,
                                  zip: _vm.card.zip
                                },
                                on: {
                                  "update:name": function($event) {
                                    return _vm.$set(_vm.card, "name", $event)
                                  },
                                  "update:number": function($event) {
                                    return _vm.$set(_vm.card, "number", $event)
                                  },
                                  "update:month": function($event) {
                                    return _vm.$set(_vm.card, "month", $event)
                                  },
                                  "update:year": function($event) {
                                    return _vm.$set(_vm.card, "year", $event)
                                  },
                                  "update:cvv": function($event) {
                                    return _vm.$set(_vm.card, "cvv", $event)
                                  },
                                  "update:zip": function($event) {
                                    return _vm.$set(_vm.card, "zip", $event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.form.xActionType === "Card" &&
                          _vm.paymentProcessor === "square" &&
                          !_vm.squareTerminalConnecting &&
                          _vm.squareScriptLoaded
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "t-ml-12 t-px-6 t-flex t-justify-center"
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "t-px-6 t-pt-6 t-pb-2 t-flex t-flex-col t-items-center\n                      t-border t-border-solid t-border-blue-400 t-rounded-md",
                                    attrs: { id: "card-container" }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _vm.form.xActionType === "Card" &&
                              _vm.paymentProcessor === "clover" &&
                              _vm.cloverScriptLoaded &&
                              !_vm.cloverTerminalConnecting
                                ? _c("CloverCardForm", {
                                    staticClass: "t-px-6",
                                    attrs: {
                                      amount: _vm.total,
                                      loaded: _vm.cloverFormLoaded
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.cloverTerminalConnecting ||
                          _vm.squareTerminalConnecting
                            ? _c("div", { staticClass: "t-flex-grow" }, [
                                _c(
                                  "div",
                                  { staticClass: "t-flex t-justify-center" },
                                  [
                                    _c("DeviceForm", {
                                      staticClass: "t-px-6",
                                      attrs: {
                                        status: _vm.terminalStatus,
                                        connected: _vm.terminalConnected
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                ])
              ]),
              _vm._l(_vm.invoices, function(invoice, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "t-mx-6 t-my-3 t-bg-gray-100" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "t-flex t-border t-border-solid t-border-gray-300 t-rounded-md\n                 t-my-2 t-px-4 t-py-2"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-flex-grow t-flex t-justify-between t-pr-8"
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "t-text-gray-500 t-text-xs" },
                                [_vm._v("Job id")]
                              ),
                              _c("div", { staticClass: "t-text-base" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/jobs/" + invoice.job_id,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(invoice.job_id) + " ")]
                                )
                              ])
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "t-text-gray-500 t-text-xs" },
                                [_vm._v("Total balance")]
                              ),
                              _c("div", { staticClass: "t-text-base" }, [
                                _vm._v("$" + _vm._s(invoice.total_balance))
                              ])
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "t-text-gray-500 t-text-xs" },
                                [_vm._v("Remaining balance")]
                              ),
                              _c("div", { staticClass: "t-text-base" }, [
                                _vm._v("$" + _vm._s(invoice.remaining_balance))
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-w-72" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "t-mb-1",
                                attrs: {
                                  "label-width": "0",
                                  prop: "payments." + index + ".amount",
                                  "show-message": false,
                                  rules: [
                                    {
                                      required: true
                                    }
                                  ]
                                }
                              },
                              [
                                _c("el-input", {
                                  staticClass: "t-w-full",
                                  attrs: {
                                    size: "small",
                                    type: "number",
                                    placeholder: "Amount"
                                  },
                                  model: {
                                    value: _vm.form.payments[index].amount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.payments[index],
                                        "amount",
                                        $$v
                                      )
                                    },
                                    expression: "form.payments[index].amount"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("el-input", {
                              attrs: { size: "small", placeholder: "Memo" },
                              model: {
                                value: _vm.form.payments[index].memo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.payments[index],
                                    "memo",
                                    $$v
                                  )
                                },
                                expression: "form.payments[index].memo"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "t-border-0 t-border-t t-border-solid t-border-gray-300 t-py-6"
            },
            [
              _vm.displayFeeOption
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "t-flex t-items-baseline t-px-6" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-gray-400 t-mr-2 t-text-base t-flex-grow t-text-right"
                          },
                          [_vm._v(" Applied Total ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-text-xl t-font-medium t-text-gray-400"
                          },
                          [_vm._v(" $" + _vm._s(_vm.appliedTotal) + " ")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "t-flex t-items-baseline t-px-6" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "t-mr-2 t-text-base t-flex-grow t-text-right"
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.includeFee,
                                  callback: function($$v) {
                                    _vm.includeFee = $$v
                                  },
                                  expression: "includeFee"
                                }
                              },
                              [_vm._v("Pass through fee")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "t-text-gray-400" }, [
                          _vm._v(" $" + _vm._s(_vm.passthroughTotal) + " ")
                        ]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right-end",
                              trigger: "hover",
                              content: _vm.feePopoverContent
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning-outline t-ml-2",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "t-flex t-items-baseline t-px-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right"
                  },
                  [_vm._v(" Total ")]
                ),
                _c("div", { staticClass: "t-text-xl t-font-medium" }, [
                  _vm._v("$" + _vm._s(_vm.total))
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "t-bg-gray-200 t-py-3 t-px-6 t-flex t-justify-between"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" Close")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    icon: "el-icon-plus",
                    size: "small"
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" Add ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }