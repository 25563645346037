const state = {
  isOpen: false,
  filters: {
    cardView: 0,
    date: new Date(),
    week: null,
    shops: [],
    view: 'list',
    rowView: false,
    warehouses: {
      mygrant: true,
      pilk: true,
      pgw: true,
      igc: true,
      service: true,
      other: true
    },
    solidCards: false,
    weekOffset: 0,
    calendarSort: 'techs',
    showTotals: false,
    showInstallLocation: false,
    dayCounters: {
      morning: true,
      'mid day': true,
      afternoon: true,
      'time not selected': true
    },
    jobConfirmationDetailsVisible: false,
    context: 'all',
    showTags: false,
    showAvailability: false,
    showVendors: false,
    jobTypes: [
      'callBack',
      'dropOff',
      'pickUp',
      'rAndI',
      'recal',
      'tint',
      'warranty',
      'workOrder'
    ]
  },
  activeJobListFilter: {
    label: 'To reschedule',
    value: 'reschedule'
  },
  data: {},
  bays: [],
  jobDetails: null,
  geometry: [],
  showUnmapped: false,
  loading: false,
  leftLoading: false,
  bounds: null,
  warehouses: [],
  draggingShopId: null,
  editTech: null,
  waypointLoading: false,
  jobDetailsLoading: false,
  count: null,
  dataToUpdate: {},
  jobFilterCount: {},
  jobList: [],
  bayJobs: [],
  routeEntryDetails: null,
  techsideLocations: {},
  routesModified: false,
  waypointModalTech: null
}

export default state
