import { markRaw } from 'vue';

import AccountingReportsIcon from '../ui/AccountingReportsIcon.vue';
import InstallReportsIcon from '../ui/InstallReportsIcon.vue';
import MiscellaneousIcon from '../ui/MiscellaneousIcon.vue';
import PaymentIcon from '../ui/PaymentIcon.vue';
import SalesReportsIcon from '../ui/SalesReportsIcon.vue';
import SalesTaxIcon from '../ui/SalesTaxIcon.vue';
import ScheduleReportsIcon from '../ui/ScheduleReportsIcon.vue';
import StatementsIcon from '../ui/StatementsIcon.vue';

const images = {
	'accounting-reports': markRaw(AccountingReportsIcon),
	'install-reports': markRaw(InstallReportsIcon),
	miscellaneous: markRaw(MiscellaneousIcon),
	payment: markRaw(PaymentIcon),
	'sales-reports': markRaw(SalesReportsIcon),
	'sales-tax': markRaw(SalesTaxIcon),
	'schedule-reports': markRaw(ScheduleReportsIcon),
	statements: markRaw(StatementsIcon)
};

export const reportsMenuConfig = [
	{
		id: 'sales-reports',
		label: 'Sales Reports',
		desc: 'Track and analyze sales performance with detailed metrics.',
		img: images['sales-reports'],
		items: [
			{
				id: 'sales-report',
				label: 'Sales Report',
				filters: 6,
				desc: 'A complete analysis of sales volumes for a selected period, including dynamics, key indicators and trends to support strategic decision-making.',
				to: '/reports'
			},
			{
				id: 'margin-report',
				label: 'Margin Report',
				filters: 6,
				desc: 'Detailed profit reporting showing margins by product, category and segment to help optimize pricing and costs.',
				to: '/reports?tab=margin'
			},
			{
				id: 'customer-summary',
				label: 'Customer Summary',
				filters: 4,
				desc: 'A summary report on your customer base, providing information on purchasing behavior, order volumes and key metrics for customer relationship management.',
				to: '/reports?tab=customer-summary'
			},
			{
				id: 'royalty-summary',
				label: 'Royalty Summary',
				filters: 2,
				to: '/reports?tab=royalty-summary',
				desc: 'A Royalty Summary Report is a concise document summarizing royalty payments, revenues, and obligations for licensors and licensees over a specific time period.'
			}
		]
	},
	{
		id: 'sales-tax',
		label: 'Sales Tax',
		desc: 'Ensure accurate tax calculations and compliance.',
		img: images['sales-tax'],
		items: [
			{
				id: 'accrual-detail',
				label: 'Accrual Detail',
				filters: 3,
				desc: 'Detailed sales tax reporting, including all transactions and corresponding tax amounts for complete accounting.',
				to: '/reports?tab=tax-detail'
			},
			{
				id: 'accrual-summary',
				label: 'Accrual Summary',
				filters: 3,
				desc: 'A sales tax assessment summary report that provides an overall picture of tax liability for a selected period.',
				to: '/reports?tab=tax-summary'
			},
			{
				id: 'cash-basis-detail',
				label: 'Cash Basis Detail',
				filters: 3,
				desc: 'A report detailing taxes based on the cash method, showing the exact tax amounts for payments and receipts.',
				to: '/reports?tab=detail'
			},
			{
				id: 'cash-basis-summary',
				label: 'Cash Basis Summary',
				filters: 3,
				desc: 'A summary of sales taxes based on the cash basis, allowing you to quickly estimate your total tax liability.',
				to: '/reports?tab=summary'
			}
		]
	},
	{
		id: 'schedule-reports',
		label: 'Schedule Reports',
		desc: 'Manage report timelines and delivery schedules.',
		img: images['schedule-reports'],
		items: [
			{
				id: 'schedule-report',
				label: 'Schedule Report',
				filters: 3,
				desc: 'Detailed schedule report including scheduled tasks, activities and their status for effective time management.',
				to: '/reports?tab=schedule'
			},
			{
				id: 'manifest',
				label: 'Manifest',
				filters: 0,
				desc: 'A summary document containing detailed information about planned deliveries, routes and related data for logistics coordination.',
				command: () => alert('This feature is coming soon!')
			}
		]
	},
	{
		id: 'install-reports',
		label: 'Install Reports',
		desc: 'Monitor and document installation processes efficiently.',
		img: images['install-reports'],
		items: [
			{
				id: 'installers',
				label: 'Installers',
				filters: 6,
				desc: `Detailed report on installers' work, including completed tasks, performance and installation status.`,
				to: '/reports?tab=installers'
			},
			{
				id: 'sales-reps',
				label: 'Sales Reps',
				filters: 6,
				desc: `Sales rep activity and performance report showing sales volumes, conversions and key metrics.`,
				to: '/reports?tab=salesreps'
			},
			{
				id: 'sales-sources',
				label: 'Sales Sources',
				filters: 6,
				desc: `Analysis of sales sources, providing information on customer acquisition channels and their contribution to total sales.`,
				to: '/reports?tab=salesources'
			},
			{
				id: 'locations',
				label: 'Locations',
				filters: 6,
				desc: `Location report containing performance, sales, and key metrics for each location.`,
				to: '/reports?tab=locations'
			},
			{
				id: 'tech-collections',
				label: 'Tech Collections',
				filters: 6,
				desc: `Report on the activities of technicians, including work progress status and equipment collection statistics.`,
				to: '/reports?tab=tech-collections'
			},
			{
				id: 'customer-service',
				label: 'Customer Service Reps',
				filters: 6,
				desc: `A report on the performance of customer service representatives, detailing their interactions, customer feedback, and resolved requests.`,
				to: '/reports?tab=csrs'
			}
		]
	},
	{
		id: 'payment',
		label: 'Payment',
		desc: 'Simplify payment tracking and processing tasks.',
		img: images['payment'],
		items: [
			{
				id: 'payment-receipt',
				label: 'Payment Receipt',
				filters: 6,
				desc: 'Detailed record of payment transactions, providing confirmation of received payments along with associated details like date, amount, and payer information.',
				to: '/reports?tab=receipt'
			}
		]
	},
	{
		id: 'statements',
		label: 'Statements',
		desc: 'Generate clear and concise financial statements.',
		img: images['statements'],
		items: [
			{
				id: 'account-statement',
				label: 'Account Statement',
				filters: 3,
				desc: 'A comprehensive summary of account activity, including transactions, balances, and any outstanding amounts for the selected period.',
				to: '/reports?tab=account-statement'
			},
			{
				id: 'invoice-report',
				label: 'Open Invoice Report',
				filters: 3,
				desc: 'An organized list of all unpaid invoices, helping to track outstanding payments and manage cash flow effectively.',
				to: '/reports?tab=open-invoice'
			},
			{
				id: 'aging-report',
				label: 'Aging Report',
				filters: 1,
				desc: 'A breakdown of outstanding invoices categorized by the length of time they have been overdue, offering insights into collection priorities.',
				to: '/reports?tab=aging'
			}
		]
	},
	{
		id: 'accounting-reports',
		label: 'Accounting Reports',
		desc: 'Review financial data with comprehensive accounting insights.',
		img: images['accounting-reports'],
		items: [
			{
				id: 'profit-and-loss',
				label: 'Profit and Loss',
				filters: 2,
				desc: 'A financial statement summarizing revenues, expenses, and net profit or loss over a specific time period.',
				to: '/reports?tab=profit-and-loss'
			},
			{
				id: 'balance-sheet',
				label: 'Balance Sheet',
				filters: 2,
				desc: "A snapshot of the company's financial position, showcasing assets, liabilities, and equity at a specific point in time.",
				to: '/reports?tab=balance-sheet'
			}
		]
	},
	{
		id: 'miscellaneous',
		label: 'Miscellaneous Reports',
		desc: 'Access a variety of reports for unique business needs.',
		img: images['miscellaneous'],
		items: [
			{
				id: 'sales',
				label: 'Sales Rep/Source',
				filters: 0,
				desc: 'A report analyzing sales performance by representatives or sources, providing insights into contribution to overall revenue.',
				command: () => alert('This feature is coming soon!')
			},
			{
				id: 'right-part',
				label: 'Right Part',
				filters: 2,
				desc: 'Detailed report focusing on parts or inventory items, helping to track availability and usage trends.',
				to: '/reports?tab=requests'
			},
			{
				id: 'nags-license',
				label: 'Nags License',
				filters: 3,
				desc: 'A specialized report providing information on licensing and compliance with NAGS (National Auto Glass Specifications).',
				to: '/reports?tab=nags-license',
				visible: 'admin'
			},
			{
				id: 'edi',
				label: 'EDI Report',
				filters: 2,
				desc: 'A report summarizing electronic data interchange transactions, ensuring smooth and accurate data exchange between systems.',
				to: '/reports?tab=edi',
				visible: 'admin'
			},
			{
				id: 'install',
				label: 'Install List',
				filters: 3,
				desc: 'A comprehensive list of installation jobs, including details on status, assigned personnel, and scheduling.',
				to: '/reports?tab=install-list'
			}
		]
	}
];
