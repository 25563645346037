<template>
  <div>
    <div
      class="email-dialog"
      @drop.prevent="documentDrop"
      @dragleave.stop.prevent="dragLeave"
      @dragenter.stop.prevent="dragEnter"
      @dragover.prevent
      @drop="dragging = false"
      :class="{
        'dragging-overlay': dragging,
        'uploading-file': uploadingDropFile
      }"
    >
      <el-form
        ref="form"
        :model="data"
        :rules="rules"
        label-width="140px"
        size="small">
        <el-form-item prop="selectedTemplate">
          <span slot="label" class="undo-label-styles">Template</span>
          <el-select
            clearable
            placeholder="Select text message template (optional)"
            v-model="selectedTemplateId"
            style="width: 100%">
            <el-option
              v-for="item in templates"
              :label="item.name"
              :key="item.id"
              :value="item.id">
            </el-option>
          </el-select>
          or <a href="javascript:void(0)" @click="manageTemplates">Manage text message templates</a>
        </el-form-item>
        <consumer-select :consumerToCharge.sync="data.consumerToCharge" />
        <amount />
        <passthrough-fee v-if="displayPassthroughFeeOption" />
        <el-form-item prop="to">
          <span slot="label" class="undo-label-styles">To email</span>
          <el-select
            placeholder="Select or enter email"
            v-model="data.to"
            style="width: 100%"
            allow-create
            clearable
            default-first-option
            filterable>
            <el-option
              v-for="(email, key) in toOptions"
              :label="email"
              :key="key"
              :value="email"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="replyTo">
          <span slot="label" class="undo-label-styles">Reply To</span>
          <el-input v-model="data.replyTo" />
        </el-form-item>
        <el-form-item prop="subject">
          <span slot="label" class="undo-label-styles">Subject</span>
          <el-input v-model="data.subject" />
        </el-form-item>
        <el-form-item prop="body">
          <span slot="label" class="undo-label-styles">Body</span>
          <vue-editor
            v-model="data.body"
            :editorOptions="quill.editorOptions"
            @selection-change="quill.onSelectionChange"
            @focus="quill.onFocus"/>
        </el-form-item>
        <el-form-item style="word-break: break-word;">
          <p class="mt-3"><span style="color: #F56C6C;">NOTE:</span> A link to the customer payment portal will be automatically added in the end of the message.</p>
        </el-form-item>
        <el-form-item>
      <div class="t-flex t-justify-between t-mb-2">
        <div class="email-dialog-attachments">
          <div class="attachments-label">Attachments</div>
          <div class="t-flex t-mt-1 t-mb-1">
            <div>
              <el-switch
                class="t-mt-1"
                v-model="sendPdf"
                :active-text="`${status} #${$job.job.id}`"
              />
              <div
                v-for="doc in numberedDocs"
                :key="doc.id"
                class="t-mb-1 t-mt-1"
              >
                <el-switch
                  :active-text="`${getDocConfig(doc.document_type).label} ${
                    doc.number ? doc.number : ''
                  }`"
                  :active-color="getDocConfig(doc.document_type).color"
                  :value="!!docs.find((id) => id === doc.id)"
                  @input="toggleDoc(doc.id)"
                />
              </div>
            </div>
            <div class="t-ml-3">
              <pdf-type-select
                :copy.sync="pdf.copy"
                v-if="pdf"
                template="select"
                wrap
                end
                allowSetDefault
              />
            </div>
          </div>
          <div class="t-flex t-mb-2" :key="receiptsUpdate">
            <div>
              <div
                v-for="receipt in paymentReceipts"
                :key="receipt.id"
                class="t-mb-1"
              >
                <div style="display: inline-flex">
                  <el-switch
                    :key="`pmt-switch-${receipt.id}`"
                    v-model="receipt.sendPdf"
                    @change="toggleReceiptSwitch(receipt.id)"
                  />
                  <div class="t-ml-2" :key="`pmt-label-${receipt.id}`">
                    <a
                      v-if="receipt.link && receipt.sendPdf"
                      :href="receipt.link"
                      target="_blank"
                    >
                      {{
                        `Pmt. ${receipt.type || ""} - $${receipt.amount} (${
                          receipt.consumer_name
                        })`
                      }}
                    </a>
                    <span
                      v-else
                      v-loading="receipt.loading"
                      element-loading-spinner="el-icon-loading"
                      class="receipt-label"
                    >
                      {{
                        `Pmt. ${receipt.type || ""} - $${receipt.amount} (${
                          receipt.consumer_name
                        })`
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="additional-attachments t-pl-2">
          <div class="additional-attachments__label t-mb-1">
            Additional Attachments
          </div>
          <div class="additional-attachments__files">
            <div
              v-for="(attachment, index) in attachments"
              :key="attachment.url"
              class="t-mb-1"
            >
              <a :href="attachment.url" target="_blank">{{ attachment.name }}</a
              >&nbsp;
              <a @click="deleteAttachment(index)" href="javascript:void(0)">
                <font-awesome-icon :icon="faTimes" class="remove-icon t-ml-2" />
              </a>
            </div>
            <div class="t-mt-2" @dragover.prevent>
              <FilePicker
                :buttonLabel="'Choose File'"
                :filePickerKey="filePickerKey"
                :handleFilePickerResponse="handleFilePickerResponse"
                :dropListenerName="'emailAttachment'"
                :uploadingDropFile.sync="uploadingDropFile"
              />
              <div class="t-mt-1">
                <div v-if="!uploadingDropFile">Or Drag and Drop...</div>
                <div v-if="uploadingDropFile">Uploading...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-button
          class="mb-2 mr-2"
          type="primary"
          @click="handleSubmit"
          :loading="loading || receiptLoading">
          Send
        </el-button>
        <el-button
          class="ml-0"
          plain
          @click="$emit('close')">
          Close
        </el-button>
      </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import PdfTypeSelect from "@/components/JobPageV2/helpers/PDFTypeSelect";
import FilePicker from "@/components/Helpers/FilePicker.vue";
import FontAwesomeIcon from "@fortawesome/vue-fontawesome";
import TextMessageTemplate from "@/scripts/objects/text_message_template";
import Pdf from "@/scripts/objects/pdf";
import ConsumerSelect from './FormItem/ConsumerSelect.vue'
import Amount from './FormItem/Amount.vue'
import PassthroughFee from "./FormItem/PassthroughFee.vue";
import faTimes from "@fortawesome/fontawesome-free-solid/faTimes";
import { capitalCase } from "change-case";
import { getNumberedDocList } from "@/scripts/helpers/document.helpers";
import { documentsConfig } from "@/constants/documents_v2.config";
import { paymentGroupRepository } from "@/scripts/repositories/paymentGroup";
import { get, call } from "vuex-pathify";
import moment from "moment";
import * as filestack from "filestack-js";
import * as R from "ramda";

import Emoji from "quill-emoji";
import { VueEditor, Quill } from "vue2-editor";
import "quill-emoji/dist/quill-emoji.css";
import { VendorTypeIds } from "@/scripts/helpers/gb_vendor.helpers";

var fonts = Quill.import('formats/font');
fonts.whitelist = ['arial', 'courier', 'dancing', 'garamond', 'times', 'trebuchet', 'verdana'];
var alignStyle = Quill.import('attributors/style/align');

Quill.register(
  fonts,
  true
)

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

Quill.register(alignStyle, true);


export default {
  props: ['filePickerKey'],
  components: {
    PdfTypeSelect,
    FilePicker,
    FontAwesomeIcon,
    ConsumerSelect,
    Amount,
    PassthroughFee,
    VueEditor
  },
  data() {
    return {
      loading: false,
      data: {
        to: '',
        replyTo: this.$store.state.user?.replyto_email_cache || '',
        subject: '',
        body: '',
        amount: '',
        typeId: '',
      },
      pdf: { copy: this.$job.shop.pdf_default || "withPrices" },
      docs: [],
      csrEmails: [],
      techEmails: [],
      salesrepEmails: [],
      selectedTemplateId: "",
      templates: [],
      templateBodySelection: {
        index: 0,
        length: 0,
      },
      attachments: [],
      uploadingDropFile: false,
      paymentReceipts: [],
      receiptsUpdate: 0,
      dragging: 0,
      rules: {
        to: { required: true, message: "Email is required", trigger: 'change' },
        subject: { required: true, message: "Subject is required", trigger: 'change' },
        amount: { required: true, validator: (rule, value, callback) => {
          if (this.amount <= 0) {
            callback(new Error('Please enter a positive amount'))
          } else if (!this.amount) {
            callback(new Error('Please enter a valid amount'))
          } else {
            callback()
          }
        }, trigger: 'change'}
      },
      quill: {
        editorOptions: {
          modules: {
            toolbar: {
              container: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                  { align: "" },
                  { align: "center" },
                  { align: "right" },
                  { align: "justify" },
                ],
                ["blockquote", "code-block"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { list: "check" },
                ],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link"],
                ["clean"], // remove formatting button
                ["emoji"],
              ],
              handlers: {
                emoji: function () {},
              },
            },
            short_name_emoji: true,
            toolbar_emoji: true,
            textarea_emoji: false,
          },
        },
        onSelectionChange: (range) => {
          this.templateBodySelection = range;
        },
        onFocus: (quill) => {
          this.quill = quill;
        },
      }
    };
  },
  computed: {
    amount: get("twilio/textMessages/jobPayment/amount"),
    balanceDetails: get('twilio/textMessages/jobPayment/balanceDetails'),
    displayPassthroughFeeOption: get("twilio/textMessages/jobPayment/displayPassthroughFeeOption"),
    includePassthroughFee: get("twilio/textMessages/jobPayment/includePassthroughFee"),
    jobId: get('twilio/textMessages/jobPayment/jobId'),
    passthroughTotal: get("twilio/textMessages/jobPayment/passthroughTotal"),
    paymentWasSentBefore: get('twilio/textMessages/jobPayment/paymentWasSentBefore'),
    shopId: get('twilio/textMessages/jobPayment/shopId'),
    total: get('twilio/textMessages/jobPayment/total'),
    typeId: get('twilio/textMessages/jobPayment/typeId'),
    toOptions() {
      return R.pipe(
        R.flatten,
        R.filter(R.pipe(R.isNil, R.not)),
        R.uniq
      )([
        ...(this.$job?.customer?.emails?.map(R.prop("email")) || []),
        ...(this.$job?.billTo?.emails?.map(R.prop("email")) || []),
        ...this.csrEmails,
        ...this.salesrepEmails,
        ...this.techEmails
      ])
    },
    status() {
      return capitalCase(this.$job.job.status || '')
    },
    numberedDocs() {
      return this.$job.jobDocs ? getNumberedDocList(this.$job.jobDocs) : []
    },
    sendPdf: {
      get() {
        return !!this.pdf
      },
      set(v) {
        if (v) {
          this.pdf = { copy: this.$job.shop.pdf_default }
        } else {
          this.pdf = null
        }
      }
    },
    faTimes() {
      return faTimes
    },
    receiptLoading() {
      return R.any(R.propEq('loading', true))(this.paymentReceipts)
    }
  },
  mounted() {
    this.$root.$on("email-dialog-refresh-templates", this.getTemplates);
    this.getTemplates();
    this.data = Object.assign({}, {
      ...this.data,
      subject: 'Payment Link',
      body: `Please click the link below to make your payment. Thank you for your business!`
    })
    this.setPaymentReceipts();
    this.csrEmails = []
    this.salesrepEmails = []
    this.techEmails = []
    this.getVendorEmails()
  },
  beforeDestroy() {
    this.$root.$off("email-dialog-refresh-templates", this.getTemplates);
  },
  methods: {
    openEmailTemplatesDialog: call("twilio/textMessages/emailTemplates/open"),
    setTextMessageTemplatesReferral: call(
      "twilio/textMessages/emailTemplates/setReferral"
    ),
    setAmount: call('twilio/textMessages/jobPayment/setAmount'),
    async getVendorEmails() {
      for (const vendor of this.$job.jobGbVendors) {
        if (vendor.gb_vendor_type_id == VendorTypeIds.CSR) {
          const { data } = await this.$unum.vendor().get(`/data/${vendor.gb_vendor_id}/emails`)
          this.csrEmails.push(data)
          continue;
        }

        if (vendor.gb_vendor_type_id == VendorTypeIds.SALESREP) {
          const { data } = await this.$unum.vendor().get(`/data/${vendor.gb_vendor_id}/emails`)
          this.salesrepEmails.push(data)
          continue;
        }

        if (vendor.gb_vendor_type_id == VendorTypeIds.TECH) {
          const { data } = await this.$unum.vendor().get(`/data/${vendor.gb_vendor_id}/emails`)
          this.techEmails.push(data)
          continue;
        }
      }
    },
    async getTemplates() {
      let { data } = await this.$unum
        .job()
        .get(`/textMessageTemplate/byTypeId/7/${this.$job.job.shop_id}`);
      this.templates = data;
      if (!this.signatureLink) {
        this.templates.forEach((template) => {
          if (template.default === 1) {
            this.selectedTemplateId = template.id;
          }
        });
        if (
          !this.selectedTemplateId &&
          this.templates &&
          this.templates.length === 1
        ) {
          this.selectedTemplateId = this.templates[0].id;
        }
      }
    },
    manageTemplates() {
      this.openEmailTemplatesDialog();
      this.setTextMessageTemplatesReferral("job-email");
    },
    handleFilePickerResponse: function (fileName, fileUrl) {
      this.attachments.push({
        name: fileName,
        url: fileUrl
      })
    },
    deleteAttachment (index) {
      const attachments = this.attachments
      attachments.splice(index, 1)
      this.attachments = attachments
    },
    getDocConfig(type) {
      return documentsConfig[type] || {}
    },
    toggleDoc(url) {
      this.docs = R.ifElse(
        R.find(R.equals(url)),
        R.reject(R.equals(url)),
        R.append(url)
      )(this.docs)
    },
    documentDrop: function (e) {
      this.$root.$emit('emailAttachment', e)
    },
    dragEnter() {
      this.dragging -= 1
    },
    dragLeave() {
      this.dragging += 1
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (await this.$refs.form.validate()) {
          if (this.paymentWasSentBefore) {
            await this.$messageBox.confirm(
              'You previously have sent a payment request for this job. Are you sure you want to send another one ?',
              'Warning',
              {
                type: 'warning',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    this.send()
                  }
                  done()
                }
              }
            )
          } else {
            this.send()
          }
        }
      } catch (err) {
        if (err.error === 'The message From/To pair violates a blacklist rule.') {
          this.$message.warning('Customer previously replied STOP to this number, so this message can\'t be delivered.')
        }
        this.loading = false
      }
    },
    async send () {
      const receipts = this.paymentReceipts.filter(receipt => receipt.sendPdf).map(receipt => { 
        return {
          name: `Pmt. ${receipt.type || ''} - $${receipt.amount} (${receipt.consumer_name})`,
          url: receipt.link
        }
      })
      // get corresponding document urls from selected document ids
      const attachedDocs = this.numberedDocs.reduce((result, docObj) => {
        if (this.docs.includes(docObj.id)) result.push(docObj.url)
        return result
      }, [])

      const passthroughTotal = this.includePassthroughFee ? this.passthroughTotal : 0

      const status = await TextMessageTemplate.sendPayment({
        additionalAttachments: this.attachments.concat(receipts).map(a => a.url),
        amount: this.amount,
        body: this.data.body,
        consumer_id: this.data.consumerToCharge.id,
        consumer_name: this.data.consumerToCharge.name,
        docs: attachedDocs,
        jobId: this.jobId,
        passthrough: passthroughTotal,
        pdf: this.pdf,
        replyTo: this.$store.state.user?.replyto_email_cache || '',
        subject: this.data.subject,
        to: [this.data.to],
        total: this.total,
        type: 'email',
        typeId: this.typeId,
      })
      if (typeof status === 'string' && (status.includes('Attachments too large') || status.includes('Attachments too big'))) {
        this.$message.error(status)
        this.loading = false
        return
      }
      this.$message.success(`Job Payment link successfully sent.`)
      this.loading = false
      this.$emit('sent')
    },
    setPaymentReceipts() {
      let receipts = JSON.parse(JSON.stringify(this.$job.jobPayments)) || []
      receipts = receipts.map(receipt => {
        const existAttachment = this.attachments.find(attachment => {
          return receipt.payment_group_id === attachment.paymentGroupId
        })
        if (existAttachment) {
          receipt.sendPdf = true
          receipt.link = existAttachment.url
          this.attachments = this.attachments.filter(attachment => {
            return receipt.payment_group_id !== attachment.paymentGroupId
          })
        } else {
          receipt.sendPdf = false
        }
        return receipt
      })
      this.paymentReceipts = receipts
    },
    async toggleReceiptSwitch(receiptId) {
      const receipt = this.paymentReceipts.find(r => {
        return r.id === receiptId
      })
      if (receipt) {
        if (receipt.sendPdf && !receipt.link) {
          receipt.link = await this.generateReceiptLink(receiptId)
        }
        this.receiptsUpdate++
      }
    },
    async generateReceiptLink(receiptId) {
      const receipt = this.paymentReceipts.find(r => {
        return r.id === receiptId
      })
      let link = null
      if (receipt) {
        this.$set(receipt, 'loading', true)
        this.loading = true
        try {
          const paymentGroup = await paymentGroupRepository.getPaymentGroupDetails(receipt.payment_group_id)
          const shop = this.$store.state.shops.find(i => i.id === paymentGroup.shop_id)
          const date = moment(paymentGroup.date).format('LL')
          let amount = 0
          for (const i of paymentGroup.payments) {
            amount += i.amount
          }
          const totalAmount = Math.round(amount * 100) / 100

          const data = await Pdf.renderPaymentReceiptPdf({
            shop,
            paymentGroup,
            date,
            totalAmount
          })

          const dataBlob = new Blob([data.data], { type: 'application/pdf'})
          const URL = window.URL || window.webkitURL
          const client = filestack.init(this.filePickerKey)
          const filename = `Pmt. ${receipt.type || ''} - $${receipt.amount} (${receipt.consumer_name})`
          const file = await client.upload(dataBlob, {}, { filename: filename })
          link = file.url
        } catch (error) {
          this.$message({ type: 'error', message: 'Something went wrong' })
        }
      }
      this.$set(receipt, 'loading', false)
      this.loading = false
      return link
    },
  },
  watch: {
    selectedTemplateId: async function (value) {
      if (value !== "") {
        const templateData = await TextMessageTemplate.generateMessageBody(
          value,
          this.jobId
        );
        this.data.body = templateData.body;
        this.data.subject = templateData.subject;
      } else {
        this.data.body = "";
        this.data.subject = "";
        Object.assign(this.data, { subject: this.defaultSubject });
      }
    },
    'data.consumerToCharge' (v) {
      this.setAmount(v.amount)
    }
  },
};
</script>

<style lang="scss">
  .el-dialog__header {
    font-size: 14px;
  }

  .additional-attachments {
    width: 40%;

    &__files {
      line-height: 12px;

      .remove-icon {
        color: $crimson-red;
        vertical-align: text-bottom;

        &:hover {
          color: $watermelon-pink;
        }
      }
      button {
        font-size: 12px;
        padding: .275rem .65rem;
      }
    }
  }

  .receipt-label .el-loading-spinner {
    top: 0;
    margin-top: 0;
  }
  
  .dragging-overlay {
    &:after {
      content: "Drop Files to Upload";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: transparentize($primary, 0.7);
      border: 3px dashed $primary;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }

  .uploading-file {
    &:after {
      content: "";
      position: absolute;
      top: $padding-xs;
      bottom: $padding-xs;
      left: $padding-xs;
      right: $padding-xs;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      color: $primary;
    }
  }

  .new-item-2 {
    background-color: #409EFF;
    color: white;
    border-radius: 5px;
    padding: 0px 5px;
    margin-left: 5px;
    font-size: x-small;
    line-height: 1.5;
  }

  .ql-emoji > svg {
    border-radius: 10px;
    background-color: rgba(238, 255, 0, 0.6); 
  }

</style>
