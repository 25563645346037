import { types } from './types'
import { Message, MessageBox } from 'element-ui'
import {
  userSettingsEndpoint,
  schedulerWaypointUrl,
  schedulerJobDetailsV2Endpoint,
  techsideCompleteLegacyUrl,
  schedulerJobUpdateAddressEndpoint,
  shopTechsUrl,
  schedulerAppointmentUrl,
  techUrl,
  schedulerStopEndpoint,
  schedulerWarehousesUrl,
  schedulerJobDetailsUrl,
  schedulerJobListEndpoint,
  schedulerBaysEndpoint,
  schedulerDataEndpoint,
  schedulerRoutesEndpoint,
  schedulerClearRoutesEndpoint,
  schedulerRouteEntryStopEndpoint
} from '@/constants/endpoints'
import { generateBayUpdateObject } from '@/scripts/helpers/scheduler_v2.helpers'
import {
  generateUpdatedRouteEntries,
  getSchedulerRequestParams
} from '@/scripts/helpers/scheduler_v3.helpers'
import * as R from 'ramda'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import { getFormData } from '@/scripts/helpers/scheduler.helpers'
import { fixAppointment } from '@/scripts/helpers/appointment.helpers'
import objectDiff from 'object-diff'
import Api from '@/services/Api'
import LegacyApi from '@/services/LegacyApi'
import Vue from 'vue'
const actions = {
  async [types.updateUserSettings](context, value) {
    await Api.post(userSettingsEndpoint, value)
  },
  async [types.getSchedulerData](
    { state, commit, rootGetters, dispatch },
    silent = false
  ) {
    if (!silent) {
      commit('SET_LOADING', true)
    }

    const { data } = await Vue.prototype.$unum.sch().get('/data/schedule', {
      params: getSchedulerRequestParams(state, rootGetters)
    })

    commit('SET_DATA', data)
    commit('SET_LOADING', false)
    await dispatch(types.getSchedulerJobList)
  },
  [types.mapUpdate]({ state, commit }, route) {
    commit(
      'SET_DATA_TO_UPDATE',
      route
    )
  },
  [types.updateSchedulerItems](
    { state, commit, dispatch },
    { items, route, date, useDate }
  ) {
    if (route) {
      const route_entries = generateUpdatedRouteEntries(
        route,
        date || state.filters.date
      )(items)
      const updatedRoute = { [route.id]: { route_entries } }
      commit('SET_DATA', R.mergeDeepRight(state.data, updatedRoute))
      commit(
        'SET_DATA_TO_UPDATE',
        R.mergeDeepRight(state.dataToUpdate, updatedRoute)
      )
    }
  },
  async [types.updateBayItems]({ state, commit }, { items, bay_id }) {
    if (bay_id) {
      const newValues = generateBayUpdateObject(
        formatToDb(state.filters.date),
        bay_id
      )(items)
      commit('SET_BAY_JOBS', R.mergeDeepRight(state.bayJobs, newValues))
      await Api.post(schedulerDataEndpoint, { jobs: newValues })
    }
  },
  async [types.postSchedulerUpdates]({ state, commit, dispatch }) {
    const dataToSend = {}
    for (const [key, value] of Object.entries(state.dataToUpdate)) {
      dataToSend[key] = {
        route_entries: value?.route_entries?.map(re => {
          return {
            ...re,
            job: {
              ...R.pick([
                'gb_route_gb_vendor_id',
                'scheduled_date',
                'scheduler_order',
                'id',
                'tech_commission',
                'tech_commission_flat_or_percent'
              ], re.job)
            }
          }
        }) || []
      }
    }
    commit('SET_LOADING', true)
    try {
      await Vue.prototype.$unum.sch().post('/data/schedule', dataToSend)
    } catch (err) {
      MessageBox({
        dangerouslyUseHTMLString: true,
        message: err?.response?.data || 'An error occurred',
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: 'notification-message-box',
        onClose: () => {
          commit('SET_DATA_TO_UPDATE', {})
          commit('SET_ROUTES_MODIFIED', true)
          dispatch(types.getSchedulerData, true)
        },
      })
      return
    }
    Message.success({ message: 'Scheduler data synced', duration: 900 })
    commit('SET_DATA_TO_UPDATE', {})
    // dispatch(types.getSchedulerCounts)
    commit('SET_ROUTES_MODIFIED', true)
    await dispatch(types.getSchedulerData, true)
    // if (state.filters.view === 'map') dispatch(types.getSchedulerMapData, true)
  },
  async [types.createRouteWaypoint]({ state, commit }, { waypoint, event }) {
    const { data } = await Api.post(schedulerWaypointUrl, waypoint)
    commit('SET_WAYPOINTS', R.assoc(data.id, data, state.data.waypoints))
    if (event) {
      event.to.removeChild(event.item)
    }
  },
  async [types.deleteWaypoint]({ state, commit }, id) {
    await Api.delete(`${schedulerWaypointUrl}/${id}`)
    commit('SET_WAYPOINTS', R.dissoc(id, state.data.waypoints))
  },
  async [types.getJobDetails]({ state, commit }, id) {
    commit('SET_LOADING', true)
    const { data } = await Vue.prototype.$unum.sch().get(`data/job-detail/${id}`) // await Api(schedulerJobDetailsV2Endpoint(id))
    commit('SET_JOB_DETAILS', data)
    commit('SET_LOADING', false)
  },
  async [types.updateJobDetailsValue]({ state, commit, dispatch }, values) {
    const { jobDetails } = state
    const { key, value } = values
    commit('SET_JOB_DETAILS', R.assoc(key, value, jobDetails))
    const id = jobDetails.id || values.id
    await Api.post(schedulerJobDetailsUrl(id), { [key]: value })
    dispatch(types.getSchedulerData)
    dispatch(types.getBays)
  },
  async [types.updateJobDetailsValues]({ state, commit, dispatch }, values) {
    const { jobDetails } = state
    commit('SET_JOB_DETAILS', R.merge(jobDetails, values))
    const { id } = jobDetails
    await Api.post(schedulerJobDetailsUrl(id), values)
    dispatch(types.getSchedulerData)
    dispatch(types.getBays)
  },
  async [types.completeTechsideJob]({ commit, state }, id) {
    await LegacyApi.post(
      techsideCompleteLegacyUrl(id),
      getFormData({ isTechsign: true })
    )
    const { jobDetails } = state
    commit(
      'SET_JOB_DETAILS',
      R.assoc('techside_job_completed', true, jobDetails)
    )
  },
  async [types.updateJobAddressByPlaceId]({ commit, state }, { place_id, id }) {
    commit('SET_LOADING', true)
    const { data } = await Api.post(schedulerJobUpdateAddressEndpoint(id), {
      place_id
    })
    commit('SET_JOBS', R.assoc(id, data, state.data.jobs))
    commit('SET_JOB_DETAILS', data)
    commit('SET_LOADING', false)
    Message.success({ message: 'Job updated', duration: 900 })
  },
  async [types.getShopTechs]({ commit }, shopId) {
    const { data } = await Api.get(`${shopTechsUrl(shopId)}`)
    return data
  },
  async [types.saveAppointment]({ commit, state }, appointment) {
    const { data } = await Api.post(
      schedulerAppointmentUrl,
      fixAppointment(appointment)
    )
    commit('SET_APPOINTMENTS', R.assoc(data.id, data, state.data.appointments))
    Message.success({ message: 'Appointment synced', duration: 900 })
  },
  async [types.getTechInfo]({ state, commit }, { id, shop_id }) {
    const { data } = await Api({ url: techUrl(shop_id, id) })
    commit('SET_EDIT_TECH', data)
  },
  async [types.updateTechInList]({ commit, state }, updatedTech) {
    const diff = R.pick(
      [
        'color',
        'name',
        'status',
        'techside_invitation_status',
        'techside_username'
      ],
      objectDiff(state.editTech, updatedTech)
    )
    const index = state.techs.findIndex(R.propEq('id', updatedTech.id))
    commit(
      'SET_TECHS',
      R.over(R.lensIndex(index), tech => ({ ...tech, ...diff }), state.techs)
    )
    commit('SET_EDIT_TECH', null)
  },
  async [types.getSchedulerMapData](
    { state, commit, getters, rootGetters },
    skipBounds
  ) {
    // commit('SET_LOADING', true)
    // if (!skipBounds) commit('SET_BOUNDS', null)
    // try {
    //   const params = {
    //     scheduled_date: formatToDb(state.filters.date),
    //     shops: state.filters.shops,
    //     combined: rootGetters.getUserCombineTechsValue
    //   }
    //   const { data } = await Api.get(schedulerMapUrl, { params })
    //   commit('SET_GEOMETRY', data)
    //   if (!skipBounds) commit('SET_BOUNDS', getters.routeStates[0])
    // } catch (error) {}
    // commit('SET_LOADING', false)
  },
  async [types.getSchedulerWarehouses]({ state, commit }) {
    const { data } = await Api.get(schedulerWarehousesUrl)
    commit('SET_WAREHOUSES', data)
  },
  [types.addStatusToConfirmationHistory]({ commit }, payload) {
    commit('ADD_STATUS_TO_CONFIRMATION_HISTORY', payload)
  },
  async [types.getSchedulerJobList]({ commit, state }) {
    commit('SET_LEFT_LOADING', true)
    const { data = {} } = await Vue.prototype.$unum.sch().get('/data/list-with-counts', {
      params: {
        shops: R.path(['filters', 'shops'])(state),
        type: R.path(['activeJobListFilter', 'value'])(state)
      }
    })

    const { count, list } = data
    commit('SET_JOB_FILTER_COUNT', count)
    commit('SET_JOB_LIST', list)
    commit('SET_LEFT_LOADING', false)
  },
  async [types.getSchedulerCounts]({ commit, state }) {
    const { data = {} } = await Vue.prototype.$unum.sch().get('/data/list-with-counts', {
      params: {
        shops: R.path(['filters', 'shops'])(state),
        type: R.path(['activeJobListFilter', 'value'])(state),
        onlyCount: true
      }
    })

    const { count } = data
    commit('SET_JOB_FILTER_COUNT', count)
  },
  async [types.getBays]({ commit, state }) {
    const { data = {} } = await Api.get(schedulerBaysEndpoint, {
      params: {
        shops: R.path(['filters', 'shops'])(state),
        date: R.path(['filters', 'date'])(state)
      }
    })
    const { bays, jobs } = data
    commit('SET_BAYS', bays)
    commit('SET_BAY_JOBS', jobs)
  },
  async [types.createRouteTechStop]({ commit, state }, techStop) {
    const { data } = await Api.post(schedulerStopEndpoint, techStop)
    return data
  },
  async [types.calculateRoutes](
    { commit, state, dispatch },
    { techs, options, config }
  ) {
    const { data } = await Api.post(schedulerRoutesEndpoint, {
      techs,
      date: formatToDb(state.filters.date),
      shops: state.filters.shops,
      options: R.filter(R.identity)(options),
      config
    })
    if (data.error) {
      Message.error(data.error, 4000)
    } else {
      // console.log(JSON.parse(JSON.stringify(state.data)))
      await dispatch(types.getSchedulerData)
    }
  },
  async [types.clearRoutes]({ state, commit, rootGetters, dispatch }) {
    commit('SET_LOADING', true)
    await Api.get(schedulerClearRoutesEndpoint, {
      params: getSchedulerRequestParams(state, rootGetters)
    })
    dispatch(types.getSchedulerData)
  },
  async [types.createTechRouteEntryStop]({ dispatch }, body) {
    await Api.post(schedulerRouteEntryStopEndpoint, body)
    dispatch(types.getSchedulerData)
  }
}

export default actions
