import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, watch } from 'vue';

import { useDefaultDashboard } from '@/widgets/dashboard/composables/defaultDashboard';
import DashboardLayout from '@/widgets/dashboard/ui/DashboardLayout.vue';

import RevenueDashboard from './ui/RevenueDashboard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RevenueDashboardPage',
  setup(__props) {

const { graphPermission, updateDashboardByPermission } = useDefaultDashboard();

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardLayout, null, {
    default: _withCtx(() => [
      _createVNode(RevenueDashboard)
    ]),
    _: 1
  }))
}
}

})