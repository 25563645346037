var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { staticClass: "t-py-2", attrs: { prop: "amount" } },
    [
      _c("div", { staticClass: "t-flex t-items-baseline t-px-6" }, [
        _c(
          "div",
          {
            staticClass:
              "t-text-gray-400 t-mr-2 t-text-base t-flex-grow t-text-right"
          },
          [_vm._v(" Applied Total ")]
        ),
        _c("div", { staticClass: "t-text-xl t-font-medium t-text-gray-400" }, [
          _vm._v(" $" + _vm._s(_vm.amount) + " ")
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "t-flex t-items-baseline t-px-6 justify-content-center"
        },
        [
          _c(
            "div",
            { staticClass: "t-mr-2 t-text-base t-flex-grow t-text-right" },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.includePassthroughFee,
                    callback: function($$v) {
                      _vm.includePassthroughFee = $$v
                    },
                    expression: "includePassthroughFee"
                  }
                },
                [_vm._v("Pass through fee")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "t-text-gray-400" }, [
            _vm._v(" $" + _vm._s(_vm.passthroughTotal) + " ")
          ]),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "right-end",
                trigger: "hover",
                content: _vm.feePopoverContent
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-warning-outline t-ml-2",
                attrs: { slot: "reference" },
                slot: "reference"
              })
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "t-flex t-items-baseline t-px-6" }, [
        _c(
          "div",
          {
            staticClass:
              "t-text-gray-600 t-mr-2 t-text-base t-flex-grow t-text-right"
          },
          [_vm._v(" Total ")]
        ),
        _c("div", { staticClass: "t-text-xl t-font-medium" }, [
          _vm._v("$" + _vm._s(_vm.total))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }