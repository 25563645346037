import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "tw3-flex tw3-justify-end tw3-gap-2" }

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { DashboardType } from '../../model/enum';


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultDashboardDialog',
  props: /*@__PURE__*/_mergeModels({
    selected: {}
  }, {
    "show": { type: Boolean },
    "showModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["change-default"], ["update:show"]),
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;
const emit = __emit;

const visible = _useModel<boolean>(__props, 'show');

const localSelected = ref<DashboardType>();
const categories = ref([
	{ name: t('revenue'), key: DashboardType.revenue },
	{ name: t('technicians'), key: DashboardType.tech }
]);

const save = () => {
	if (localSelected.value) emit('change-default', localSelected.value);
	visible.value = false;
};

watch(
	() => visible.value,
	() => {
		if (visible.value) {
			localSelected.value = props.selected;
		}
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: visible.value,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
    header: _ctx.$t('selectDefault'),
    modal: "",
    style: {"width":"18rem"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category) => {
        return (_openBlock(), _createElementBlock("div", {
          key: category.key,
          class: "tw3-flex tw3-items-center tw3-gap-2 tw3-mb-4"
        }, [
          _createVNode(_unref(RadioButton), {
            modelValue: localSelected.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localSelected).value = $event)),
            inputId: category.key,
            name: "dynamic",
            value: category.key
          }, null, 8, ["modelValue", "inputId", "value"]),
          _createElementVNode("label", {
            class: "!tw3-text-lg",
            for: category.key
          }, _toDisplayString(category.name), 9, _hoisted_1)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Button), {
          label: _ctx.$t('cancel'),
          severity: "secondary",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (visible.value = false))
        }, null, 8, ["label"]),
        _createVNode(_unref(Button), {
          label: _ctx.$t('save'),
          type: "button",
          onClick: save
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}
}

})