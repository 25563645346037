import ObjectTracker from '@/scripts/objects/objectTracker'
import Api from '@/services/Api'
import $ from 'jquery'
import LegacyApi from '@/services/LegacyApi'

export default class Ediqueue extends ObjectTracker {
  id = -1
  submit_date_time = ''
  response_date_time = ''
  status = 'Unsubmitted'
  job_edi_message = ''
  job_id = -1
  edi_creation_date_time = ''
  service = ''
  edi_id = -1
  remit_received = -1
  glaxis_id = -1

  constructor (initialObject = {}) {
    super()
    super.setInitial(initialObject)
  }

  static getJobEdiqueue (shopId, jobId, callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/shop/${shopId}/job/${jobId}/ediqueue`}).then(res => {
      
      callbackFunction(new Ediqueue(res.data))
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getNextEdiSubmissionTime (callbackFunction) {
    Api({url: `/api/nextEdiSubmissionTime`}).then(res => {
      
      callbackFunction(res.data.secondsToSubmission)
    })
  }

  static async updateJobEdiqueue (job_id, status, message, successCallbackFunction, errorCallbackFunction) {
    var newStatus = new FormData()
    newStatus.set('status', status)
    newStatus.set('msg', message)
    try {
      const { data } = await LegacyApi.post('/edi/setstatus/' + job_id, JSON.stringify({status: status, msg: message}))
      if (typeof data.error !== 'undefined') {
        alert(data.error)
        errorCallbackFunction()
      } else {
        if (typeof data.invoiceStatus === 'undefined' || typeof data.invoiceStatusDetails === 'undefined') {
          alert('Updated status not received from server.')
        } else {
          successCallbackFunction(data.invoiceStatus, data.invoiceStatusDetails)
        }
      }
    } catch (error) {
      
    }
  }

  static getRejectedEdisCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/rejectedEdisCount`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }

  static getQueuedEdisCountsForAllConnectedShops (callbackFunction, errorCallbackFunction = null) {
    Api({url: `/api/queuedEdisCount`}).then(res => {
      
      callbackFunction(res.data)
    }, function (error) {
      if (errorCallbackFunction) {
        errorCallbackFunction(error)
      }
    })
  }
}
