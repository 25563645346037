import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tw3-flex tw3-flex-col tw3-max-w-[1200px] tw3-w-full tw3-mx-auto" }
const _hoisted_2 = { class: "tw3-sticky tw3-flex tw3-items-center tw3-justify-between tw3-top-0 tw3-py-[1rem] gb-default-bg tw3-z-10" }
const _hoisted_3 = { class: "layout-breadcrumb tw3-text-gray-500 tw3-mr-4" }
const _hoisted_4 = { class: "tw3-flex tw3-flex-col lg:tw3-flex-row tw3-gap-0 lg:tw3-gap-4 tw3-p-4 tw3-pt-0 tw3-bg-white tw3-border-slate-200 tw3-rounded-md" }
const _hoisted_5 = { class: "tw3-flex tw3-flex-col tw3-flex-1" }
const _hoisted_6 = { class: "tw3-text-xl tw3-font-extrabold tw3-bg-white tw3-px-4 tw3-py-6" }
const _hoisted_7 = {
  key: 0,
  class: "tw3-w-full tw3-h-full tw3-flex tw3-justify-center tw3-items-center tw3-border-t"
}
const _hoisted_8 = { class: "tw3-text-xl tw3-text-center tw3-py-7" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "tw3-w-full tw3-flex tw3-items-center tw3-gap-4 tw3-p-4 tw3-border-t tw3-border-b" }
const _hoisted_11 = { class: "icon-component" }
const _hoisted_12 = { class: "tw3-w-full tw3-flex tw3-flex-col tw3-gap-2" }
const _hoisted_13 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_14 = { class: "tw3-text-xl tw3-font-bold" }
const _hoisted_15 = { class: "tw3-text-gray-500 tw3-text-sm" }
const _hoisted_16 = { class: "tw3-flex tw3-flex-col" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "tw3-flex tw3-items-center tw3-gap-2" }
const _hoisted_19 = { class: "tw3-text-blue-500 tw3-font-semibold tw3-text-lg" }
const _hoisted_20 = { class: "tw3-text-gray-500 tw3-text-sm" }

import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/entities/user/lib/store';

import { reportsMenuConfig } from './lib/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseReportsPage',
  setup(__props) {

const i18n = useI18n();
const breadcrumbs = [{ label: i18n.t('reports') }];

const userStore = useUserStore();
const router = useRouter();
const searchQuery = ref('');

const config = computed(() => {
	const configArray = [];
	for (let i = 0; i < reportsMenuConfig.length; i++) {
		if (reportsMenuConfig[i].id === 'accounting-reports') {
			if (
				userStore.accountPackagesState.state &&
				userStore.accountPackagesState.state.length > 0
			) {
				configArray.push(reportsMenuConfig[i]);
			}
		} else if (reportsMenuConfig[i].id === 'miscellaneous') {
			const items = reportsMenuConfig[i].items;
			if (!userStore.checkUserCan('admin') && items) {
				for (let j = 0; j < items.length; j++) {
					if (items[j].id === 'nags-license' || items[j].id === 'edi') {
						items.splice(j, 1);
						j = items.length;
					}
				}
				configArray.push({ ...reportsMenuConfig[i], items });
			} else {
				configArray.push(reportsMenuConfig[i]);
			}
		} else {
			configArray.push(reportsMenuConfig[i]);
		}
	}
	return configArray;
});

const filteredConfig = computed(() => {
	if (!searchQuery.value.trim()) return config.value;
	return config.value
		.map(group => {
			const filteredItems = group.items.filter(item =>
				item.label.toLowerCase().includes(searchQuery.value.toLowerCase())
			);
			return { ...group, items: filteredItems };
		})
		.filter(group => group.items.length > 0);
});

const select = (item: {
	id: string;
	label: string;
	to?: string;
	command?: () => void;
}) => {
	if (item.to) router.push(item.to);
	else if (item.command) item.command();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "gb-header-cover gb-default-bg" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("ol", null, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(breadcrumbs, (breadcrumbRoute) => {
            return _createElementVNode("li", {
              key: breadcrumbRoute,
              class: "tw3-cursor-pointer hover:tw3-opacity-40 tw3-transition-all"
            }, _toDisplayString(breadcrumbRoute.label), 1)
          }), 64))
        ])
      ]),
      _createVNode(_unref(IconField), { class: "tw3-w-full sm:tw3-w-1/2" }, {
        default: _withCtx(() => [
          _createVNode(_unref(InputIcon), { class: "pi pi-search" }),
          _createVNode(_unref(InputText), {
            modelValue: searchQuery.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
            class: "tw3-w-full",
            placeholder: "Search reports...",
            type: "text"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "sm:tw3-w-[51px]" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('allReports')), 1),
        (!filteredConfig.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('noData')), 1)
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(filteredConfig.value, (group) => {
              return (_openBlock(), _createElementBlock("div", {
                id: group.id,
                key: group.id
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(group.img)))
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("h2", _hoisted_14, _toDisplayString(group.label), 1),
                      _createVNode(_unref(Tag), null, {
                        default: _withCtx(() => [
                          _createTextVNode("Reports: " + _toDisplayString(group.items.length), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(group.desc), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id,
                      class: "tw3-flex tw3-flex-col tw3-gap-2 tw3-px-8 tw3-py-4 tw3-border-b tw3-border-slate-200 last:tw3-border-b-0 tw3-bg-white hover:tw3-bg-transparent tw3-cursor-pointer tw3-transition-all",
                      onClick: ($event: any) => (select(item))
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(item.label), 1),
                        _createVNode(_unref(Tag), { severity: "info" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Filters: " + _toDisplayString(item.filters), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_20, _toDisplayString(item.desc), 1)
                    ], 8, _hoisted_17))
                  }), 128))
                ])
              ], 8, _hoisted_9))
            }), 128))
      ])
    ])
  ]))
}
}

})