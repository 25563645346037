import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

import { onMounted, watch } from 'vue';

import { useDefaultDashboard } from '@/widgets/dashboard/composables/defaultDashboard';
import DashboardLayout from '@/widgets/dashboard/ui/DashboardLayout.vue';

import { DashboardType } from '../../widgets/dashboard/model/enum';

import RevenueDashboard from './ui/RevenueDashboard.vue';
import TechDashboard from './ui/TechDashboard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardPage',
  setup(__props) {

const { defaultDashboard, graphPermission, updateDashboardByPermission } =
	useDefaultDashboard();

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardLayout, null, {
    default: _withCtx(() => [
      (_unref(graphPermission))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(defaultDashboard) === _unref(DashboardType).revenue)
              ? (_openBlock(), _createBlock(RevenueDashboard, { key: 0 }))
              : _createCommentVNode("", true),
            (_unref(defaultDashboard) === _unref(DashboardType).tech)
              ? (_openBlock(), _createBlock(TechDashboard, { key: 1 }))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(TechDashboard, { key: 1 }))
    ]),
    _: 1
  }))
}
}

})